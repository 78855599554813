import { jwtDecode } from "jwt-decode";

const useDownloadButtonStatus = (): boolean => {
  const token = sessionStorage.getItem("token") ?? "";
  if(token) {
    const tokenDecode: any = jwtDecode(token) ?? {};
    if(Object.keys(tokenDecode).length) {
      const email = tokenDecode?.email ?? "";
      const isDownloadButtonEnable = email !== "callcenter01@smartfren.com";

      return isDownloadButtonEnable;
    }
  }
  return false;
};

export default useDownloadButtonStatus;
