import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessCategoryData } from ".";
import useInputChange from "customhook/useInputChange";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { GroupBusinessData } from "pages/GroupBusiness";

const BusinessCategoryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const categoryCodeParam = location.state?.categoryCodeParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/business-category";
  const isAdd = currentPath === `${pathParent}/add`;
  const isView = currentPath === `${pathParent}/view`;

  const noAction = () => {};

  const initialData: BusinessCategoryData = useMemo(() => {
    return {
      category_code: "",
      category_name: "",
      category_desc: "",
      group_code: "",
      active: false,
      active_label: "",
      groups: [],
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<BusinessCategoryData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchCategoryCode = useCallback(async () => {
    try {
      const response: MainResponse<any> | null = await fetchAPI(
        "business/categories/next"
      );

      const code = response?.data?.next ?? "";

      setData({
        category_code: code,
      });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchGroups = useCallback(async () => {
    try {
      const response: MainRowsResponse<GroupBusinessData> | null =
        await fetchAPI("business/groups");

      const groups = response?.data?.rows ?? [];

      setData({ groups: groups });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<BusinessCategoryData> | null =
        await fetchAPI(`business/categories/${categoryCodeParam}`);

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [categoryCodeParam, initialData, setData]);

  const saveItem = async () => {
    try {
      const urlPath = categoryCodeParam
        ? `business/categories/${data?.category_code}`
        : "business/categories";

      const method = categoryCodeParam ? "PUT" : "POST";

      const defaultGroupCode =
        data.groups?.length > 0 ? data.groups[0].group_code : "";

      const groupCode =
        data.group_code?.length > 0 ? data.group_code : defaultGroupCode;

      const saveData = {
        ...data,
        group_code: groupCode,
        groups: undefined,
        active_label: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        if (isAdd) {
          fetchCategoryCode();
        }
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      if (isAdd) {
        fetchCategoryCode();
      }
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const onSelectGroupChange = (groupCodeSelected: string): void => {
    setData({ group_code: groupCodeSelected });
  };

  const onSaveClicked = async () => {
    saveItem();
  };

  const onCancelClicked = () => {
    back();
  };

  useEffect(() => {
    fetchGroups();

    if (categoryCodeParam) {
      fetchData();
    } else {
      fetchCategoryCode();
    }
  }, [categoryCodeParam, fetchData, fetchGroups, fetchCategoryCode]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Business Category
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of business category
          </p>
        </div>
      </div>
      <div className="">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="input-category-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category Code
              </label>
              <div className="mt-2">
                <input
                  id="input-category-code"
                  name="category_code"
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={noAction}
                  value={data.category_code}
                  disabled={true}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="input-category-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category Name
              </label>
              <div className="mt-2">
                <input
                  id="input-category-name"
                  name="category_name"
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView ? noAction : onInputChange}
                  value={data.category_name}
                />
              </div>
            </div>

            <div className="col-span-full sm:col-span-4">
              <label
                htmlFor="group"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Group
              </label>
              <div className="mt-2">
                <select
                  id="select-group"
                  name="group"
                  autoComplete="group"
                  value={data?.group_code}
                  onChange={
                    isView
                      ? noAction
                      : (event) => {
                          const selectedGroupCode = event?.target?.value;
                          onSelectGroupChange(selectedGroupCode);
                        }
                  }
                  className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {data?.groups?.map((group) => (
                    <option key={group.group_code} value={group.group_code}>
                      {group.group_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Descriptions
              </label>
              <div className="mt-2">
                <textarea
                  id="textarea-desc"
                  name="category_desc"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView ? noAction : onInputChange}
                  value={data.category_desc}
                />
              </div>
            </div>

            <div className="col-span-full flex items-center">
              <input
                id="checkbox-active"
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                name="active"
                checked={data.active}
                onChange={isView ? noAction : onInputChange}
              />
              <label htmlFor="active" className="ml-2 text-gray-700">
                Active
              </label>
            </div>
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {categoryCodeParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default BusinessCategoryForm;
