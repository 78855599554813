// AuthGuard.js
import React, { ReactNode, useState } from "react";

import { Navigate } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Layout from "../components/Layout";
import Contents from "../components/Contents";

interface AuthGuardProps {
  isNotAllowed?: boolean;
  children?: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ isNotAllowed, children }) => {
  const isAuthenticated = true;

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  if (!isAuthenticated || isNotAllowed) {
    return <Navigate to="/cms/login" />;
  }

  return (
    <>
      <Layout onClickMenuIcon={toggleSidebar} />
      {isSidebarVisible && <Sidebar />}
      <Contents isSidebarVisible={isSidebarVisible}>{children}</Contents>
    </>
  );
};

export default AuthGuard;
