import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListView from "components/ListView/ListView";
import { NumericFormat } from "react-number-format";
import TransactionStatusComponent from "./TransactionStatusComponent";
import {
  DataDetailType,
  columnInfo,
  columnCustomerInfo,
  columnPlatformInfo,
  columnPaymentInfo,
  columnFulfillInfo
} from "./TransactionType";

const TransactionDetailComponent = (props: { data: DataDetailType[], isRouteBack: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataSingle, setDataSingle] = useState<any>({});
  const [data, setData] = useState<DataDetailType[]>([]);

  useEffect(() => {
    setData(props.data);
    setDataSingle(props.data[0]);
  }, [props]);

  return (
    <div className="container w-full flex flex-wrap mx-auto">
      <section className="w-full">
        <button
          onClick={() => navigate(props.isRouteBack, {state: location.state})}
          className="bg-gray-200 hover:bg-gray-300 font-medium text-gray-800 py-2 px-4 rounded inline-flex items-center mb-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <h1 className="flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl">
          Summary
        </h1>
        <hr className="bg-gray-300 my-5" />
        <div className="flex justify-between px-2">
          <div className="font-sans font-bold break-normal text-gray-700 text-xl">
            Order ID: #{dataSingle?.partner_order_id}
          </div>
        </div>

        <h4 className="font-sans break-normal text-gray-700 px-2">
          Transaction Time : {dataSingle?.transaction_date}
        </h4>
        <h4 className="font-sans pb-8 break-normal text-gray-700 px-2">
          Category : {dataSingle?.category}
        </h4>

        {/* ORDER INFO */}
        <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-4 text-xl">
          Product Details
        </h2>
        <div id="section2" className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
          {data.length > 0 ? (
            <ListView columns={columnInfo} rowItems={data} />
          ) : (
            <>
              {columnInfo.map((item, i) => (
                <div className="md:flex mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                      htmlFor="my-textfield"
                    >
                      {item?.label}
                    </label>
                  </div>
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-600 font-semibold md:text-left mb-3 md:mb-0 pr-4"
                      htmlFor="my-textfield"
                    >
                      {dataSingle?.[item.field] ?? "-"}
                    </label>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        {/* PAYMENT INFO */}
        <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pt-8 pb-4 text-xl">
          Payment Details
        </h2>
        <div id="section3" className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
          <div className="flex justify-between">
            <div className="w-full">
              {columnPaymentInfo.map((item, i) => (
                <div className="md:flex mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                      htmlFor="my-textfield"
                    >
                      {item?.label}
                    </label>
                  </div>
                  <div className="md:w-1/3">
                    {item?.type === "money" ? (
                      <NumericFormat
                        className="block text-gray-600 font-semibold md:text-left mb-3 md:mb-0 pr-4"
                        type="text"
                        value={dataSingle?.[item.field]}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        decimalSeparator=","
                        readOnly={true}
                      />
                    ) : item?.type === "status" ? (
                      <TransactionStatusComponent
                        status={dataSingle?.payment_status}
                      />
                    ) : (
                      <label
                        className="block text-gray-600 font-semibold md:text-left mb-3 md:mb-0 pr-4"
                        htmlFor="my-textfield"
                      >
                        {dataSingle?.[item.field] || "-"}
                      </label>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div></div>
          </div>
        </div>

        {/* Fulfill INFO */}
        <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pt-8 pb-4 text-xl">
          Fulfillment
        </h2>
        <div id="section4" className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
          <div className="flex justify-between">
            <div className="w-full">
              {columnFulfillInfo.map((item, i) => (
                <div className="md:flex mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                      htmlFor="my-textfield"
                    >
                      {item?.label}
                    </label>
                  </div>
                  <div className="md:w-1/3">
                    {item?.type === "status" ? (
                      <TransactionStatusComponent
                        status={dataSingle?.fulfillment_status}
                      />
                    ) : (
                      <label
                        className="block text-gray-600 font-semibold md:text-left mb-3 md:mb-0 pr-4"
                        htmlFor="my-textfield"
                      >
                        {dataSingle?.[item.field] ?? "-"}
                      </label>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* PLATFORM INFO */}
        <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pt-8 pb-4 text-xl">
          Platform Info
        </h2>
        <div id="section5" className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
          {columnPlatformInfo.map((item, i) => (
            <div className="md:flex mb-6">
              <div className="md:w-1/3">
                <label
                  className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                  htmlFor="my-textfield"
                >
                  {item.label}
                </label>
              </div>
              <div className="md:w-1/3">
                <label
                  className="block text-gray-600 font-semibold md:text-left mb-3 md:mb-0 pr-4"
                  htmlFor="my-textfield"
                >
                  {dataSingle?.[item.field] ?? "-"}
                </label>
              </div>
            </div>
          ))}
        </div>

        {/* CUSTOMER INFO */}
        <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pt-8 pb-4 text-xl">
          Customer Info
        </h2>
        <div id="section7" className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
          {columnCustomerInfo.map((item, i) => (
            <div className="md:flex mb-6">
              <div className="md:w-1/3">
                <label
                  className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                  htmlFor="my-textfield"
                >
                  {item.label}
                </label>
              </div>
              <div className="md:w-1/3">
                <label
                  className="block text-gray-600  md:text-left mb-3 md:mb-0 pr-4"
                  htmlFor="my-textfield"
                >
                  {dataSingle?.[item.field] ?? "-"}
                </label>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default TransactionDetailComponent;
