import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const PaginationView: React.FC<{
  itemsPerPage: number;
  countRows: number;
  valuePaginate: Function;
}> = ({ itemsPerPage, countRows, valuePaginate }) => {
  //   const [itemOffset, setItemOffset] = useState(0);

  //   const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const handlePageClick = (event: any) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % countRows;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    // setItemOffset(newOffset);
    valuePaginate({
      limit: itemsPerPage,
      offset: newOffset,
      page: event.selected + 1,
    });
  };

  useEffect(() => {
    setSelectedPage(0);
    setPageCount(Math.ceil(countRows / itemsPerPage));
  }, [countRows, itemsPerPage]);

  useEffect(() => {}, [selectedPage]);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Next >"
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel="< Prev"
      renderOnZeroPageCount={null}
      containerClassName="flex my-5 justify-end"
      activeLinkClassName="bg-rose-500 border border-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
      pageLinkClassName="p-2 px-4 mx-1 border border-gray-200 rounded-full bg-gray-50 hover:bg-rose-500 hover:text-white hover:border-rose-500"
      previousLinkClassName="p-2 px-4 mx-1 border border-gray-200 rounded-full bg-gray-50"
      nextLinkClassName="p-2 px-4 mx-1 border border-gray-200 rounded-full bg-gray-50"
      forcePage={selectedPage}
    />
  );
};

export default PaginationView;
