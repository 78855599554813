export type PrivilegedUserType = {
  partners: [];
  categories: [];
  groups: [];
};

const usePrivilegedUsers = (): PrivilegedUserType => {
  return JSON.parse(`${sessionStorage.getItem("privileged_users")}`);
};

export default usePrivilegedUsers;
