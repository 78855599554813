/* eslint-disable react-hooks/exhaustive-deps */
import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import useDownloadButtonStatus from "customhook/useDownloadButtonStatus";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { MainRowsResponse, fetchAPI, fetchAPICustom } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type ProductInventoryData = {
  id: string;
  sku: string;
  category_code: string;
  category_name: string;
  product_code: string;
  name: string;
  partner_code: string;
  partner_name: string;
  currency: string;
  wholesale_price: string;
  client_admin_fee: string;
  created_at: string;
  updated_at?: string;
  created_at_formatted: string;
  status: string;
  status_name: string;
  selected: boolean;
  sync_at?: string;
  source?: string;
};

type CategoryListOptsType = {
  label: string;
  value: string;
};

type DownloadType = {
  data: [];
  title: string;
};

export const statusInventoryList = [
  { label: "Draft", value: "0" },
  { label: "Publish", value: "1" },
  { label: "Inactive", value: "-1" },
];

interface ProductInventoriesProps {
  productSelected?: ProductInventoryData;
  onCancel?: () => void;
  onSave?: (productSelected?: ProductInventoryData) => void;
}

const ProductInventories = (props?: ProductInventoriesProps) => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();
  const PrivilegedUsers = usePrivilegedUsers();
  const isDownloadButtonEnable = useDownloadButtonStatus();
  const [dataDownload, setDataDownload] = useState<DownloadType>({
    data: [],
    title: "export-products-inventory",
  });

  const pathParent = "/cms/products-inventory";
  const pathView = `${pathParent}/view`;
  // const pathEdit = `${pathParent}/edit`;

  const isSelect = props?.productSelected !== undefined;

  const [datas, setDatas] = useState<ProductInventoryData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const itemsPerPageDefault = 10;

  const [productSelected, setProductSelected] = useState<
    ProductInventoryData | undefined
  >(props?.productSelected);

  const [categoryListOpts, setCategoryListOpts] = useState<
    CategoryListOptsType[]
  >([]);

  const fetchDatas = async (query?: any) => {
    const queryData = {
      ...query,
      sortBy: query?.sortBy ?? "product_code.desc",
      size: query?.itemsPerPage ?? itemsPerPageDefault,
      itemsPerPage: undefined,
      page: query?.page ?? 1,
    };

    const filterOder = {};
    if (query?.filterBy && query?.search) {
      Object.assign(filterOder, { [query?.filterBy]: query?.search });
    }

    delete queryData?.filterBy;
    delete queryData?.search;

    Object.assign(filterOder, queryData);

    const qsString =
      Object.keys(filterOder).length > 0
        ? `?${queryString.stringify(filterOder)}`
        : "";

    try {
      const response: MainRowsResponse<ProductInventoryData> | null =
        await fetchAPI(`products/inventory${qsString}`);

      const datas =
        response?.data?.rows?.map((data) => {
          return {
            ...data,
            selected: data.id === productSelected?.id,
            created_at_formatted: moment(data.created_at).format(
              "DD MMM YYYY hh:mm"
            ),
            sync_at: data.sync_at ? moment(data.sync_at).format(
              "DD MMM YYYY hh:mm"
            ) : '',
            status_name:
              statusInventoryList.find(
                (status) => status.value.toString() === data.status.toString()
              )?.label ?? "",
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  };

  const fetchCategory = useCallback(async () => {
    try {
      const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
        await fetchAPI(`business/categories?inCode=${PrivilegedUsers.categories.toString()}`);
      const datas =
        response?.data?.rows.map((data: any) => {
          return {
            label: data.category_name,
            value: data.category_code,
          };
        }) ?? [];
      setCategoryListOpts(datas);
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const fetchDownloadData = useCallback(async (qString: any) => {
    try {
      const dataBody = {};
      if (qString.filterBy && qString.search) {
        if (qString.filterBy === "name") {
          Object.assign(dataBody, { productName: qString.search });
        } else if (qString.filterBy === "product_code") {
          Object.assign(dataBody, { productCode: qString.search });
        } else if (qString.filterBy === "partner_code") {
          Object.assign(dataBody, { partnerCode: qString.search });
        } else if (qString.filterBy === "partner_name") {
          Object.assign(dataBody, { partnerName: qString.search });
        } else {
          Object.assign(dataBody, { [qString.filterBy]: qString.search });
        }
      }

      if (qString.category_code) {
        Object.assign(dataBody, { categoryCode: qString.category_code });
      }

      delete qString.filterBy;
      delete qString.search;

      const itemArr: any = [];

      await fetchAPICustom({
        url: `${process.env.REACT_APP_M_API_V1}/products-inventory/export`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic bWFya2V0cGxhY2U6QiN4YWV5eUE9eFkpe1h5X1cvWEhCM1tV",
        },
        body: JSON.stringify(dataBody),
        responseType: "text",
      }).then((res: unknown) => {
        let result: string = res as string;
        var arr = result.split("\n");

        arr.forEach((key: any, i: number) => {
          itemArr.push(key.split("^"));
        });
      });

      if (itemArr.length > 0) {
        setDataDownload({ ...dataDownload, data: itemArr });
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const onListEventAction = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
    fetchCategory();
  }, []);

  const columns: any = [
    { label: "ID", field: "id" },
    { label: "SKU", field: "sku" },
    { label: "Product Code", field: "product_code" },
    { label: "Product Name", field: "name" },
    { label: "Category Name", field: "category_name" },
    { label: "Partner ID", field: "partner_code" },
    { label: "Partner Name", field: "partner_name" },
    { label: "Currency", field: "currency" },
    { label: "Wholesale Price", field: "wholesale_price" },
    { label: "Partner Fee", field: "client_admin_fee" },
    { label: "Payee Code", field: "payee_code" },
    { label: "Source", field: "source" },
    { label: "Upload Time", field: "created_at_formatted" },
    { label: "Update Time", field: "sync_at" },
    { label: "Status", field: "status_name" },
  ];

  const filter = [
    {
      label: "Search By",
      field: "filterBy",
      type: "select",
      value: "",
      options: [
        { label: "Product Code", value: "product_code" },
        { label: "Product Name", value: "name" },
        { label: "Partner ID", value: "partner_code" },
        { label: "Partner Name", value: "partner_name" },
        { label: "Payee Code", value: "payeeCode" },
        { label: "SKU", value: "sku" },
      ],
    },
    { label: "Search Data", field: "search", type: "text", value: "" },
    {
      label: "Category",
      field: "category_code",
      type: "select",
      value: "",
      options: categoryListOpts,
    },
    {
      label: "Sort By",
      field: "sortBy",
      type: "select",
      value: "",
      options: [
        { label: "Product Name Ascending", value: "name.asc" },
        { label: "Product Name Descending", value: "name.desc" },
        { label: "Upload Time Ascending", value: "created_at.asc" },
        { label: "Upload Time Descending", value: "created_at.desc" },
        { label: "Status Ascending", value: "status.asc" },
        { label: "Status Descending", value: "status.desc" },
      ],
    },
    {
      label: "Items Per Page",
      field: "itemsPerPage",
      type: "select",
      value: "",
      options: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
    },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "id",
      onClick: (id: string) => {
        navigate(pathView, { state: { idParam: id } });
      },
    },
    // {
    //   type: "edit",
    //   field: "id",
    //   onClick: (id: string) => {
    //     navigate(pathEdit, { state: { idParam: id } });
    //   },
    // },
  ];

  const columnSelection: any = {
    type: "radio",
    selectedField: "selected",
    onChange: (productSelected: ProductInventoryData) => {
      const newDatas = datas?.map((data) => {
        return { ...data, selected: data.id === productSelected.id };
      });

      setDatas(newDatas);
      setProductSelected(productSelected);
    },
  };

  const Selection = () => {
    if (!isSelect) {
      return <></>;
    }

    return (
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={props?.onCancel}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => {
            props?.onSave?.(productSelected);
          }}
          className="bg-pink-600 text-white rounded-md px-20 py-2"
        >
          <span className="font-semibold">Save</span>
        </button>
      </div>
    );
  };

  if (!currentMenu.is_view && !isSelect) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white">
      <ListView
        columns={columns}
        rowItems={datas}
        filter={filter}
        eventAction={(query: any) => onListEventAction(query)}
        columnsAction={!isSelect && columnsAction}
        columnSelection={isSelect && columnSelection}
        menu={currentMenu}
        eventActionDownload={(val: any) => fetchDownloadData(val)}
        dataActionDownload={
          !isSelect && isDownloadButtonEnable
            ? {
                data: dataDownload.data,
                title: dataDownload.title,
              }
            : undefined
        }
        pagination={{
          itemsPerPage: itemsPerPageDefault,
          countRows: rowsTotal,
        }}
      />

      <Selection />
    </div>
  );
};

export default ProductInventories;
