import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useInputChange from "customhook/useInputChange";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import { ProductPublishData, statusPublishList } from ".";
import Popup from "reactjs-popup";
import ProductInventories, {
  ProductInventoryData,
} from "pages/ProductInventory";
import { PricePopup, ProductPriceData } from "./PricePopup";
import { PoductPopup } from "./ProductPopup";

const ProductPublishForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [DataEditStatusPublish, SetDataEditStatusPublish] = useState('')

  const publishIdParam = location.state?.publishIdParam;
  const productIdParam = location.state?.productIdParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/products-publish";
  const isAdd = currentPath === `${pathParent}/add`;
  let isView = currentPath === `${pathParent}/view`;

  const noAction = () => { console.log('noAction')};

  const initialData: ProductPublishData = useMemo(() => {
    return {
      publish_id: "",
      product_id: "",
      status_publish: "0",
      status_publish_label: "",
      prices: [],
      product_inventories: [],
      is_product_select_popup_show: false,
      is_product_detail_popup_show: false,
      is_price_popup_add_show: false,
      is_price_popup_view_show: false,
      is_price_popup_edit_show: false,
    };
  }, []);

  const { data, setData } = useInputChange<ProductPublishData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchProductInventories = useCallback(async () => {
    try {
      const endpoint = productIdParam
        ? `products/inventory?sortBy=product_code.desc&code=${productIdParam}`
        : `products/inventory?sortBy=product_code.desc`;

      const response: MainRowsResponse<ProductInventoryData> | null =
        await fetchAPI(endpoint);

      const productInventories = response?.data?.rows ?? [];

      setData({ product_inventories: productInventories });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [productIdParam, setData]);

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<ProductPublishData> | null =
        await fetchAPI(`products/publish/${publishIdParam}`);
      const data: ProductPublishData =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      const prices: ProductPriceData[] =
        data?.prices?.map((price, index) => {
          return {
            ...price,
            // id: index.toString(),
            start_period: moment(price.start_period).format("YYYY-MM-DD"),
            end_period: moment(price.end_period).format("YYYY-MM-DD"),
            is_price_already_available: data.status_publish?.toString() !== '0' ? true : false,
          };
        }) ?? [];

      setData({
        ...data,
        period_from: moment(data.period_from).format("YYYY-MM-DD"),
        period_to: moment(data.period_to).format("YYYY-MM-DD"),
        prices: prices,
        period_from_initial: data.period_from,
        status_publish: data.status_publish?.toString(),
        is_initial_status_on_publish: data.status_publish?.toString() === "1",
        is_initial_status_on_inactive: data.status_publish?.toString() === "-1",
      });
      SetDataEditStatusPublish(data.status_publish?.toString() ?? '')
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [publishIdParam, initialData, setData]);

  const saveItem = async () => {
    try {
      const urlPath = publishIdParam
        ? `products/publish/${data?.publish_id}`
        : "products/publish";

      const method = publishIdParam ? "PUT" : "POST";

      const defaultProductInventoryId =
        data.product_inventories?.length > 0
          ? data.product_inventories[0].id
          : "";

      const saveData = {
        product_id: data.product_id ?? defaultProductInventoryId,
        prices: data.prices?.map((priceItem) => {
          return {
            ...priceItem,
            start_period: moment(priceItem.start_period).format("YYYY-MM-DD"),
            end_period: moment(priceItem.end_period).format("YYYY-MM-DD"),
            category_code: data?.product_inventory_selected?.category_code,
            partner_code: data?.product_inventory_selected?.partner_code,
            base_price: priceItem.base_price ?? 0,
            service_fee: priceItem.service_fee ?? 0,
            platform_fee: priceItem.platform_fee ?? 0,
            shipping_fee: priceItem.shipping_fee ?? 0,
            insurance_fee: priceItem.insurance_fee ?? 0,
            vat: priceItem.vat ?? 0,
            vat_type: "amount",
            currency: "IDR",
            id: priceItem?.id ?? undefined,
              // publishIdParam && !priceItem?.id?.includes("temporary") // temporary defined on PricePopup.tsx
              //   ? priceItem?.id
              //   : undefined,
            categories: undefined,
            partners: undefined,
          };
        }),
        period_from: moment(data.period_from).format("MM-DD-YYYY"),
        period_to: moment(data.period_to).format("MM-DD-YYYY"),
        status_publish: parseInt(data.status_publish ?? "0"),
        status_config: data.status_publish === "1",
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const onSaveClicked = async () => {
    if (!data.period_from || !data.period_to) {
      alert("Period can't be empty");
      return;
    }

    if ((data.prices?.length ?? 0) <= 0) {
      alert("Price can't be empty");
      return;
    }

    if (!checkPricesCoverPeriod()) {
      alert("Each period date must have a price");
      return;
    }

    if (!checkPriceStartDateValid()) {
      alert(
        "Start period of the price cannot be smaller than the start period of the product"
      );
      return;
    }

    if (!checkPriceEndDateValid()) {
      alert(
        "End period of the price cannot be greater than the end period of the product"
      );
      return;
    }

    if (data.status_publish === "0" && data.is_initial_status_on_publish) {
      if (
        window.confirm(
          `Are you sure you want to make this product into a draft?`
        ) === true
      ) {
        saveItem();
      }
    } else if (data.status_publish === "1") {
      if (
        window.confirm(`Are you sure you want to publish this product?`) ===
        true
      ) {
        saveItem();
      }
    } else {
      saveItem();
    }
  };

  const checkPricesCoverPeriod = useCallback(() => {
    if (
      !data.period_from ||
      !data.period_to ||
      !data?.prices ||
      data?.prices?.length <= 0
    ) {
      return false;
    }

    // Get all dates between period_from to period_to
    const datesInRange: string[] = [];
    let currentDate = moment(data.period_from.substring(0, 10), "YYYY-MM-DD");
    const endDate = moment(data.period_to.substring(0, 10), "YYYY-MM-DD");

    while (currentDate <= endDate) {
      datesInRange.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "days");
    }

    // Checks if each date has a price
    for (const date of datesInRange) {
      const priceExists = data.prices?.some((price) =>
        moment(date).isBetween(
          price.start_period.substring(0, 10),
          price.end_period.substring(0, 10),
          undefined,
          "[]"
        )
      );
      console.log('priceExists = ', priceExists)
      if (!priceExists) {
        return false;
      }
    }

    return true;
  }, [data.period_from, data.period_to, data.prices]);

  const checkPriceStartDateValid = useCallback(() => {
    if (!data?.prices || data?.prices?.length <= 0) {
      return false;
    }

    // Check if price start date is valid
    const priceStartDateValid = data.prices.every((price) =>
      moment(price.start_period.substring(0, 10)).isSameOrAfter(
        data.period_from?.substring(0, 10)
      )
    );

    return priceStartDateValid;
  }, [data.period_from, data.prices]);

  const checkPriceEndDateValid = useCallback(() => {
    if (!data?.prices || data?.prices?.length <= 0) {
      return false;
    }

    // Check if price end date is valid
    const priceEndDateValid = data.prices.every((price) =>
      moment(price.end_period.substring(0, 10)).isSameOrBefore(
        data.period_to?.substring(0, 10)
      )
    );

    return priceEndDateValid;
  }, [data.period_to, data.prices]);

  useEffect(() => {
    fetchProductInventories();

    if (publishIdParam) {
      fetchData();
    }
  }, [publishIdParam, fetchProductInventories, fetchData]);

  useEffect(() => {
    if (!data.product_id && (data.product_inventories?.length ?? 0) > 0) {
      setData({ product_id: data.product_inventories?.[0]?.id });
    }

    if (
      data.product_id &&
      data.product_inventories &&
      !data.product_inventory_selected?.sku
    ) {
      setData({
        product_inventory_selected:
          data.product_inventories.find(
            (inventory) =>
              inventory?.id?.toString() === data.product_id?.toString()
          ) ?? ({} as ProductInventoryData),
      });
    }
  }, [
    publishIdParam,
    data.product_id,
    data.product_inventories,
    data.product_inventory_selected,
    setData,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          {!isAdd && (
            <div className="sm:col-span-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Publish ID
              </label>
              <div className="mt-2">
                <input
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={data.publish_id}
                  onChange={noAction}
                  disabled={true}
                />
              </div>
            </div>
          )}

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Period
            </label>
            <Datepicker
              primaryColor={"blue"}
              useRange={false}
              asSingle={false}
              disabled={isView || data.is_initial_status_on_inactive}
              readOnly={true}
              displayFormat={"DD MMM YYYY"}
              separator={"-"}
              popoverDirection="down"
              disabledDates={[
                {
                  startDate: "1000-01-01",
                  endDate: moment().subtract(1, "days").format("MM-DD-YYYY"),
                },
              ]}
              value={{
                startDate: data.period_from ?? "",
                endDate: data.period_to ?? "",
              }}
              onChange={(value) =>
                isView
                  ? noAction()
                  : setData({
                      period_from: data.is_initial_status_on_publish ? data.period_from : value?.startDate?.toString(),
                      period_to: value?.endDate?.toString(),
                    })
              }
              containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500"
            />
          </div>

          <div className="sm:col-span-4">
            <div className="sm:col-span-4 flex justify-between items-center">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Product List Inventory Config
              </label>
              {(!isView && !data.is_initial_status_on_publish && !data.is_initial_status_on_inactive) &&  (
                <Popup
                  open={data.is_product_select_popup_show}
                  onOpen={() => {
                    setData({
                      is_product_select_popup_show: true,
                    });
                  }}
                  onClose={() => {
                    setData({
                      is_product_select_popup_show: false,
                    });
                  }}
                  trigger={
                    <div className="text-sm text-blue-600 cursor-pointer">
                      Select
                    </div>
                  }
                  modal
                  overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                  contentStyle={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: 16,
                    marginLeft: 24,
                    marginRight: 24,
                    minWidth: 500,
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <ProductInventories
                    productSelected={data.product_inventory_selected}
                    onCancel={() => {
                      setData({
                        is_product_select_popup_show: false,
                      });
                    }}
                    onSave={(productSelected) => {
                      setData({
                        product_id: productSelected?.id,
                        product_inventory_selected: productSelected,
                        is_product_select_popup_show: false,
                      });
                    }}
                  />
                </Popup>
              )}
            </div>

            <div className="relative flex mt-2">
              <input
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
                value={`${data.product_inventory_selected?.sku} - ${data.product_inventory_selected?.name}`}
                onChange={noAction}
                disabled={true}
              />
              <div className="flex">
                <Popup
                  open={data.is_product_detail_popup_show}
                  onOpen={() => {
                    setData({
                      is_product_detail_popup_show: true,
                    });
                  }}
                  onClose={() => {
                    setData({
                      is_product_detail_popup_show: false,
                    });
                  }}
                  trigger={
                    <div className="text-sm cursor-pointer flex items-center text-blue-600">
                      <button className="border border-blue-600 p-1 px-2 rounded">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  }
                  modal
                  overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                  contentStyle={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: 16,
                    minWidth: 500,
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <PoductPopup
                    productSelected={data.product_inventory_selected}
                  />
                </Popup>
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <div className="sm:col-span-4 flex justify-between items-center">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Price Seting & Fee Composition
              </label>
              {!isView &&
                !data.is_initial_status_on_inactive &&
                data.period_from &&
                data.period_to && (
                  <Popup
                    open={data.is_price_popup_add_show}
                    onOpen={() => {
                      setData({
                        is_price_popup_add_show: true,
                      });
                    }}
                    onClose={() => {
                      setData({
                        is_price_popup_add_show: false,
                      });
                    }}
                    trigger={
                      <div className="text-sm text-blue-600 cursor-pointer">
                        + Add
                      </div>
                    }
                    modal
                    overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                    contentStyle={{
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      padding: 16,
                      minWidth: 500,
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    <PricePopup
                      type="add"
                      product={data}
                      onCancel={() => {
                        setData({
                          is_price_popup_add_show: false,
                        });
                      }}
                      onSave={(price) => {
                        const prices = [...(data.prices ?? []), price];
                        setData({
                          prices: prices,
                          is_price_popup_add_show: false,
                        });
                      }}
                    />
                  </Popup>
                )}
            </div>

            {data.prices?.map((price: ProductPriceData) => (
              <div className="mt-2">
                <div className="relative flex">
                  <input
                    type="input"
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
                    value={`${price.base_price} (${
                      price.start_period
                        ? moment(price.start_period).format("DD MMM YYYY")
                        : price.start_period
                    } - ${
                      price.end_period
                        ? moment(price.end_period).format("DD MMM YYYY")
                        : price.end_period
                    })`}
                    onChange={noAction}
                    disabled={true}
                  />
                  <div className="flex">
                    {/* view */}
                    <Popup
                      open={price.is_price_popup_view_show}
                      onOpen={() => {
                        const priceUpdated = {
                          ...price,
                          is_price_popup_view_show: true,
                        };
                        setData({
                          prices: data.prices?.map((priceItem) =>
                            priceItem.id === priceUpdated.id
                              ? priceUpdated
                              : priceItem
                          ),
                        });
                      }}
                      onClose={() => {
                        const priceUpdated = {
                          ...price,
                          is_price_popup_view_show: false,
                        };
                        setData({
                          prices: data.prices?.map((priceItem) =>
                            priceItem.id === priceUpdated.id
                              ? priceUpdated
                              : priceItem
                          ),
                        });
                      }}
                      trigger={
                        <div className="text-sm cursor-pointer flex items-center text-blue-600">
                          <button className="border border-blue-600 p-1 px-2 rounded">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </button>
                        </div>
                      }
                      modal
                      overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                      contentStyle={{
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        padding: 16,
                        minWidth: 500,
                        maxHeight: "80vh",
                        overflowY: "auto",
                      }}
                    >
                      <PricePopup
                        type="view"
                        price={price}
                        product={data}
                        onCancel={() => {
                          const priceUpdated = {
                            ...price,
                            is_price_popup_view_show: false,
                          };
                          setData({
                            prices: data.prices?.map((priceItem) =>
                              priceItem.id === priceUpdated.id
                                ? priceUpdated
                                : priceItem
                            ),
                          });
                        }}
                      />
                    </Popup>
                    
                    {/* edit */}
                    {!isView && !data.is_initial_status_on_inactive && !price.is_price_already_available && (
                      <Popup
                        open={price.is_price_popup_edit_show}
                        onOpen={() => {
                          const priceUpdated = {
                            ...price,
                            is_price_popup_edit_show: true,
                          };
                          setData({
                            prices: data.prices?.map((priceItem) =>
                              priceItem.id === priceUpdated.id
                                ? priceUpdated
                                : priceItem
                            ),
                          });
                        }}
                        onClose={() => {
                          const priceUpdated = {
                            ...price,
                            is_price_popup_edit_show: false,
                          };
                          setData({
                            prices: data.prices?.map((priceItem) =>
                              priceItem.id === priceUpdated.id
                                ? priceUpdated
                                : priceItem
                            ),
                          });
                        }}
                        trigger={
                          <div className="text-sm cursor-pointer flex items-center text-blue-600">
                            <button className="text-amber-700 border border-amber-600 p-1 px-2 rounded mr-2 ml-2">
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                />
                              </svg>
                            </button>
                          </div>
                        }
                        modal
                        overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                        contentStyle={{
                          background: "#FFFFFF",
                          borderRadius: "8px",
                          padding: 16,
                          minWidth: 500,
                          maxHeight: "80vh",
                          overflowY: "auto",
                        }}
                      >
                        <PricePopup
                          type="edit"
                          price={price}
                          product={data}
                          onCancel={() => {
                            const priceUpdated = {
                              ...price,
                              is_price_popup_edit_show: false,
                            };
                            setData({
                              prices: data.prices?.map((priceItem) =>
                                priceItem.id === priceUpdated.id
                                  ? priceUpdated
                                  : priceItem
                              ),
                            });
                          }}
                          onSave={(priceUpdated) => {
                            const prices = data.prices?.map((priceItem) =>
                              priceItem.id === priceUpdated.id
                                ? {
                                    ...priceUpdated,
                                    is_price_popup_edit_show: false,
                                  }
                                : priceItem
                            );
                            setData({ prices: prices });
                          }}
                        />
                      </Popup>
                    )}

                    {/* delete */}
                    {!isView && !data.is_initial_status_on_inactive && !price.is_price_already_available && (
                      <div className="text-sm cursor-pointer flex items-center text-blue-600">
                        <button
                          className="bg-rosex-500 text-rose-700 border border-rose-600 p-1 px-2 rounded"
                          onClick={() => {
                            if (window.confirm("Are you sure?") === true) {
                              const prices = data.prices?.filter(
                                (priceItem) => priceItem.id !== price.id
                              );
                              setData({ prices: prices });
                            }
                          }}
                        >
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

<div className="sm:col-span-4 mt-8">
              <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                Created Time
              </label>
              <Datepicker
                displayFormat="DD-MM-YYYY HH:mm:ss"
                primaryColor={"blue"}
                useRange={false}
                asSingle={true}
                disabled={true}
                value={{
                  startDate: data.created_at ?? "",
                  endDate: data.created_at ?? "",
                }}
                onChange={() => false}
                containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500"
              />
            </div>

            <div className="sm:col-span-4 mt-8">
              <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                Updated Time
              </label>
              <Datepicker
                displayFormat="DD-MM-YYYY HH:mm:ss"
                primaryColor={"blue"}
                useRange={false}
                asSingle={true}
                disabled={true}
                value={{
                  startDate: data.updated_at ?? "",
                  endDate: data.updated_at ?? "",
                }}
                onChange={() => false}
                containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500"
              />
            </div>
            
            <div className="col-span-full sm:col-span-4 mt-8">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Status Publish
              </label>
              <div className="mt-2">
                <select
                  value={data?.status_publish?.toString()}
                  onChange={
                    isView
                      ? noAction
                      : (event) => {
                          const statushPublish = event?.target?.value;
                          setData({
                            status_publish: statushPublish,
                          });
                        }
                  }
                  disabled={isView || data.is_initial_status_on_inactive}
                  className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >   
                  {statusPublishList?.map((status) => (
                      <>(
                       {(DataEditStatusPublish?.toString() === '0' || publishIdParam === undefined ) && (
                          <>
                            {(status.value.toString() !== '-1') && (
                              <option key={status.value} value={status.value}>
                                {status.label}
                              </option>
                            )}
                          </>
                        )}
  
                        {(status.value.toString() !== '0' && DataEditStatusPublish?.toString() === '1') && (
                          <option key={status.value} value={status.value}>
                            {status.label}
                          </option>
                        )}

                        {(status.value.toString() === '-1' && DataEditStatusPublish?.toString() === '-1') && (
                          <option key={status.value} value={status.value}>
                            {status.label}
                          </option>
                        )}
                      )
                      </>
                  ))}

                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(!isView && (['0', '1'].includes(DataEditStatusPublish) || publishIdParam === undefined)) && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={back}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {publishIdParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductPublishForm;
