import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { fetchAPI, MainResponse, MainRowsResponse } from "helpers/apiHelpers";
import { formatRupiah } from "helpers/curencyFormat";
import { failedGetDataMessage } from "helpers/defaultMessage";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";

type GameVoucherType = {
  payment_method: string;
  initial: string;
  success: string;
  total: string;
};

type GameVoucherGrandTotalType = {
  initial: string;
  success: string;
  total: string;
};

type DashboardType = {
  game_vouchers: GameVoucherType[];
  game_vouchers_grand_total: GameVoucherGrandTotalType;
};

type ListOptsType = {
  label: string;
  value: string;
};

const Dashboard = () => {
  const currentMenu = useCurrentMenu();
  const PrivilegedUsers = usePrivilegedUsers();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<DashboardType>();
  const [categoryListOpts, setCategoryListOpts] = useState<ListOptsType[]>([]);

  const fetchGameVouchers = useCallback(async (query?: any) => {
    const filterOder = {};
    Object.assign(filterOder, {
      category: 
      query?.category ? 
        `${JSON.parse(query?.category).toString()}` : 
      PrivilegedUsers.categories.length > 0
        ? `${PrivilegedUsers.categories.toString()}` : ""
    });

    const queryData = {
      ...query,
      ...filterOder,
      dateFrom: query?.startDate,
      dateEnd: query?.endDate,
      startDate: undefined,
      endDate: undefined,
    };

    try {
      setLoading(true);
      const response: MainResponse<GameVoucherType[]> | null = await fetchAPI(
        `dashboard/vouchers?${queryString.stringify(queryData)}`
      );

      const gameVouchers: GameVoucherType[] =
        response?.data?.map((data) => {
          return {
            ...data,
            initial: formatRupiah(Number(data.initial ?? 0)),
            success: formatRupiah(Number(data.success ?? 0)),
            total: formatRupiah(Number(data.total ?? 0)),
          };
        }) ?? [];

      const grandTotal: GameVoucherGrandTotalType = {
        initial: formatRupiah(
          response?.data?.reduce(
            (acc, curr) => acc + parseInt(curr.initial ?? 0),
            0
          ) ?? 0
        ),
        success: formatRupiah(
          response?.data?.reduce(
            (acc, curr) => acc + parseInt(curr.success ?? 0),
            0
          ) ?? 0
        ),
        total: formatRupiah(
          response?.data?.reduce(
            (acc, curr) => acc + parseInt(curr.total ?? 0),
            0
          ) ?? 0
        ),
      };

      setData({
        game_vouchers: gameVouchers,
        game_vouchers_grand_total: grandTotal,
      });
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
      setLoading(false);
    }
  }, []);

  const fetchCategory = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.categories).length) {
      try {
        const qs = `?inCode=` + PrivilegedUsers?.categories.toString();
        const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
          await fetchAPI(`business/categories${qs}`);
        const datas =
          response?.data?.rows.map((data: any) => {
            return {
              label: data.category_name,
              value: data.category_code,
            };
          }) ?? [];

        setCategoryListOpts(datas);
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    }
  }, []);

  useEffect(() => {
    fetchCategory();
    fetchGameVouchers();
  }, [fetchGameVouchers, fetchCategory]);

  const columns = [
    { label: "Transaction Source", field: "payment_method" },
    { label: "Inital", field: "initial" },
    { label: "Success", field: "success" },
    { label: "Total", field: "total" },
  ];

  const filter = [
    {
      label: "Period",
      field: "periode",
      type: "date",
      value: "",
      styleTailwind: "w-full md:w-1/3 pr-3",
    },
    {
      label: "Category",
      field: "category",
      type: "select",
      selectMulti: true,
      value: "",
      options: categoryListOpts,
    },
  ];

  const footers = [
    "GRAND TOTAL",
    data?.game_vouchers_grand_total?.initial ?? "",
    data?.game_vouchers_grand_total?.success ?? "",
    data?.game_vouchers_grand_total?.total ?? "",
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div>
      <h1 className="font-sans font-bold break-normal text-gray-700 text-xl mb-8">
        Game Voucher Performance Report
      </h1>

      <ListView
        isLoading={isLoading}
        columns={columns}
        rowItems={data?.game_vouchers}
        eventAction={(query: any) => fetchGameVouchers(query)}
        filter={filter}
        menu={currentMenu}
        footers={footers}
      />
    </div>
  );
};

export default Dashboard;
