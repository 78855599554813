import { ProductInventoryData } from "pages/ProductInventory";

interface ProductPopupProps {
  productSelected?: ProductInventoryData;
}

export const PoductPopup = (props: ProductPopupProps) => {
  const noAction = () => {};
  return (
    <div className="sm:grid sm:grid-cols-1 sm:gap-4">
      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          SKU
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.sku}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Product ID
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.product_code}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Product Name
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.name}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Category ID
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.category_code}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Category Name
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.category_name}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Partner ID
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.partner_code}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Partner Name
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.partner_name}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Currency
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.currency}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Wholesale Price
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.wholesale_price}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Partner Fee
        </label>
        <div className="mt-2">
          <input
            type="input"
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={props.productSelected?.client_admin_fee}
            onChange={noAction}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};
