/* eslint-disable jsx-a11y/anchor-is-valid */
import { iconSvgHelper } from "helpers/iconSvgHelper";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const menusJson = sessionStorage.getItem("menus") ?? "[]";
  const menus: any[] = JSON.parse(menusJson) ?? [];

  const menuList = menus.map((menu: any) => {
    return {
      ...menu,
      children: menu?.children?.map((child: any) =>
        child.path === "/cms/dashboard"
          ? {
              ...child,
              pathActive: ["dashboard"],
              iconSvg: iconSvgHelper.dashboard,
            }
          : child.path === "/cms/orders"
          ? {
              ...child,
              pathActive: ["orders", "order-detail"],
              // count: 10,
              iconSvg: iconSvgHelper.orders,
            }
          : child.path === "/cms/customers"
          ? {
              ...child,
              pathActive: ["customers"],
              iconSvg: iconSvgHelper.customer,
            }
          : child.path === "/cms/reports"
          ? {
              ...child,
              pathActive: ["reports"],
              iconSvg: iconSvgHelper.reports,
            }
          : child.path === "/cms/product-variants"
          ? {
              ...child,
              pathActive: ["product-variants"],
              iconSvg: iconSvgHelper.productVariants,
            }
          : child.path === "/cms/product"
          ? {
              ...child,
              pathActive: ["product"],
              iconSvg: iconSvgHelper.product,
            }
          : child.path === "/cms/products-inventory"
          ? {
              ...child,
              pathActive: ["products-inventory"],
              iconSvg: iconSvgHelper.product,
            }
          : child.path === "/cms/products-price"
          ? {
              ...child,
              pathActive: ["products-price"],
              iconSvg: iconSvgHelper.product,
            }
          : child.path === "/cms/products-publish"
          ? {
              ...child,
              pathActive: ["products-publish"],
              iconSvg: iconSvgHelper.product,
            }
          : child.path === "/cms/settlement"
          ? {
              ...child,
              pathActive: ["settlement"],
              iconSvg: iconSvgHelper.settlement,
            }
          : child.path === "/cms/partners"
          ? {
              ...child,
              pathActive: ["partners"],
              iconSvg: iconSvgHelper.partners,
            }
          : child.path === "/cms/group-business"
          ? {
              ...child,
              pathActive: ["group-business"],
              iconSvg: iconSvgHelper.groupBusiness,
            }
          : child.path === "/cms/business-category"
          ? {
              ...child,
              pathActive: ["business-category"],
              iconSvg: iconSvgHelper.businessCategory,
            }
          : child.path === "/cms/roles"
          ? {
              ...child,
              pathActive: ["roles"],
              iconSvg: iconSvgHelper.userRole,
            }
          : child.path === "/cms/users"
          ? {
              ...child,
              pathActive: ["users"],
              iconSvg: iconSvgHelper.user,
            }
          : {
              ...child,
              path: "/cms/unimplemented",
              pathActive: ["unimplemented"],
              iconSvg: iconSvgHelper.partners,
            }
      ),
    };
  });

  return (
    <div>
      <aside
        id="sidebar"
        className="fixed hidden z-20 h-full top-0 left-0 pt-16 flex lg:flex flex-shrink-0 flex-col w-64 transition-width duration-75"
        aria-label="Sidebar"
      >
        <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex-1 px-3 bg-white divide-y space-y-1">
              {menuList.map((parentItem, i) => (
                <div className="space-y-2 pt-2">
                  <a className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-300 group transition duration-75 flex items-center p-2">
                    <div className="font-bold">{parentItem.name}</div>
                  </a>
                  <ul className="space-y-2 pb-2">
                    {parentItem.children?.map((item: any) => (
                      <li>
                        <Link
                          to={item.path}
                          className={`text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-300 group ${
                            item.pathActive?.includes(path)
                              ? "sb-menu-active"
                              : ""
                          }`}
                        >
                          {item.icon ? (
                            <img
                              src={item.icon}
                              alt="Icon"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ) : (
                            item.iconSvg
                          )}
                          <span className="ml-3">{item.name}</span>
                          {item.count && (
                            <span className="bg-gray-200 text-gray-800 ml-3 text-sm font-medium inline-flex items-center justify-center px-2 rounded-full">
                              {item.count}
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </aside>
      <div
        className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
        id="sidebarBackdrop"
      ></div>
    </div>
  );
};

export default Sidebar;
