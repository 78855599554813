import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GroupBusinessData } from ".";
import useInputChange from "customhook/useInputChange";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";

const GroupBusinessForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const groupCodeParam = location.state?.groupCodeParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/group-business";
  const isView = currentPath === `${pathParent}/view`;
  const isAdd = currentPath === `${pathParent}/add`;
  const isEdit = currentPath === `${pathParent}/edit`;

  const noAction = () => {};

  const initialData: GroupBusinessData = useMemo(() => {
    return {
      group_code: "",
      group_name: "",
      group_desc: "",
      active: false,
      active_label: "",
      groups: [],
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<GroupBusinessData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<GroupBusinessData> | null =
        await fetchAPI(`business/groups/${groupCodeParam}`);

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [groupCodeParam, initialData, setData]);

  const saveItem = async () => {
    try {
      const urlPath = groupCodeParam
        ? `business/groups/${data?.group_code}`
        : "business/groups";

      const method = groupCodeParam ? "PUT" : "POST";

      const saveData = {
        ...data,
        active_label: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const onSaveClicked = async () => {
    saveItem();
  };

  const onCancelClicked = () => {
    back();
  };

  useEffect(() => {
    if (groupCodeParam) {
      fetchData();
    }
  }, [groupCodeParam, fetchData]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Group Business
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of group business that will be use on business
            category
          </p>
        </div>
      </div>
      <div className="">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="input-group-code"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Group Code
              </label>
              <div className="mt-2">
                <input
                  id="input-group-code"
                  name="group_code"
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView || isEdit ? noAction : onInputChange}
                  value={data.group_code}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="input-group-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Group Name
              </label>
              <div className="mt-2">
                <input
                  id="input-group-name"
                  name="group_name"
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView ? noAction : onInputChange}
                  value={data.group_name}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Descriptions
              </label>
              <div className="mt-2">
                <textarea
                  id="textarea-desc"
                  name="group_desc"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView ? noAction : onInputChange}
                  value={data.group_desc}
                />
              </div>
            </div>

            {!isAdd && (
              <div className="col-span-full flex items-center">
                <input
                  id="checkbox-active"
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600"
                  name="active"
                  checked={data.active}
                  onChange={isView ? noAction : onInputChange}
                />
                <label htmlFor="active" className="ml-2 text-gray-700">
                  Active
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {groupCodeParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default GroupBusinessForm;
