import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PartnerData } from ".";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import useInputChange from "customhook/useInputChange";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { GroupBusinessData } from "pages/GroupBusiness";
import MultiSelectSearch from "components/MultiSelectSearch";

const PartnerForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const partnerCodeParam = location.state?.partnerCodeParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/partners";
  const isView = currentPath === `${pathParent}/view`;
  const isAdd = currentPath === `${pathParent}/add`;

  const noAction = () => {};

  const initialData: PartnerData = useMemo(() => {
    return {
      partner_code: "",
      partner_name: "",
      vmd_no: "",
      secret_key: "",
      email: "",
      note: "",
      group_code: "",
      category_code: "",
      account_creation: false,
      account_creation_label: "",
      groups: [],
      categories: [],
      categories_selected: [],
      categories_selected_initiated: false,
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<PartnerData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchPartnerCode = useCallback(async () => {
    try {
      const response: MainResponse<any> | null = await fetchAPI(
        "partners/next"
      );

      const code = response?.data?.next ?? "";

      setData({
        partner_code: code,
      });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchGroups = useCallback(async () => {
    try {
      const response: MainRowsResponse<GroupBusinessData> | null =
        await fetchAPI("business/groups");

      const groups = response?.data?.rows ?? [];

      setData({ groups: groups });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<PartnerData> | null = await fetchAPI(
        `partners/${partnerCodeParam}`
      );

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [initialData, partnerCodeParam, setData]);

  const saveItem = async () => {
    try {
      const urlPath = partnerCodeParam
        ? `partners/${data?.partner_code}`
        : "partners";

      const method = partnerCodeParam ? "PUT" : "POST";

      const defaultGroupCode =
        data.groups?.length > 0 ? data.groups[0].group_code : "";

      const groupCode =
        data.group_code?.length > 0 ? data.group_code : defaultGroupCode;

      const saveData = {
        ...data,
        group_code: groupCode,
        category_code:
          data.categories_selected?.map((category) => category?.value) ?? [],
        account_creation_label: undefined,
        groups: undefined,
        categories: undefined,
        categories_selected: undefined,
        categories_selected_initiated: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        if (isAdd) {
          fetchPartnerCode();
        }
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      if (isAdd) {
        fetchPartnerCode();
      }
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const setInititalCategoriesSelected = useCallback(() => {
    if (
      data?.categories_selected_initiated === false &&
      data.category_code?.length > 0
    ) {
      const categoriesSelected = data.category_code.map((category: any) => {
        return { label: category.name, value: category.code };
      });

      setData({
        categories_selected: categoriesSelected,
        categories_selected_initiated: true,
      });
    }
  }, [data?.categories_selected_initiated, data.category_code, setData]);

  const onSelectGroupChange = (groupCodeSelected: string): void => {
    setData({ group_code: groupCodeSelected });
  };

  const onSelectCategoriesChange = (groupsSelected: any): void => {
    setData({ categories_selected: groupsSelected });
  };

  const onSaveClicked = async () => {
    if (
      window.confirm(
        `Parter with ID ${data.partner_code} will be ${
          isAdd ? "saved" : "updated"
        }. Are you sure?`
      ) === true
    ) {
      saveItem();
    }
  };

  const onCancelClicked = () => {
    back();
  };

  useEffect(() => {
    fetchGroups();

    if (partnerCodeParam) {
      fetchData();
    } else {
      fetchPartnerCode();
    }
  }, [fetchData, fetchGroups, fetchPartnerCode, partnerCodeParam]);

  useEffect(() => {
    setInititalCategoriesSelected();
  }, [setInititalCategoriesSelected]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Partners
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of partner data
          </p>
        </div>
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Partner ID
            </label>
            <div className="mt-2">
              <input
                id="input-partner-code"
                name="partner_code"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={noAction}
                value={data.partner_code}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Partner Name
            </label>
            <div className="mt-2">
              <input
                id="input-partner-name"
                name="partner_name"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.partner_name}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Vmd Number
            </label>
            <div className="mt-2">
              <input
                id="input-vmd-number"
                name="vmd_no"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.vmd_no}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="input-email"
                name="email"
                type="email"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.email}
              />
            </div>
          </div>

          <div className="sm:col-span-4 hidden">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              API Key Partner
            </label>
            <div className="mt-2">
              <input
                id="input-api-key"
                name="secret_key"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.secret_key}
              />
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Group
            </label>
            <div className="mt-2">
              <select
                id="select-group"
                name="group"
                autoComplete="group"
                value={data?.group_code}
                onChange={
                  isView
                    ? noAction
                    : (event) => {
                        const selectedGroupCode = event?.target?.value;
                        onSelectGroupChange(selectedGroupCode);
                      }
                }
                className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {data?.groups?.map((group) => (
                  <option key={group.group_code} value={group.group_code}>
                    {group.group_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Business Category
            </label>
            <div className="mt-2">
              <MultiSelectSearch
                endpoint="business/categories"
                queryKey="category_name"
                labelKey="category_name"
                valueKey="category_code"
                selecteds={data.categories_selected}
                isViewOnly={isView}
                onChange={isView ? noAction : onSelectCategoriesChange}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Notes
            </label>
            <div className="mt-2">
              <textarea
                id="input-note"
                name="note"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.note}
              />
            </div>
          </div>

          <div className="col-span-full flex items-center">
            <input
              type="checkbox"
              id="active"
              className="form-checkbox h-5 w-5 text-blue-600"
              name="account_creation"
              checked={data.account_creation}
              onChange={isView ? noAction : onInputChange}
            />
            <label htmlFor="active" className="ml-2 text-gray-700">
              Account Creation
            </label>
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {partnerCodeParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default PartnerForm;
