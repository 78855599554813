import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import useInputChange from "customhook/useInputChange";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { MenuData, RolesData } from "pages/Roles";

const RoleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const roleIdParam = location.state?.roleIdParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/roles";
  const isView = currentPath === `${pathParent}/view`;

  const noAction = () => {};

  const initialData: RolesData = useMemo(() => {
    return {
      id: "",
      name: "",
      description: "",
      menus: [],
      menus_all: [],
      menus_all_combined: [],
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<RolesData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchMenusAll = useCallback(async () => {
    try {
      const response: MainRowsResponse<MenuData> | null = await fetchAPI(
        "menu"
      );

      const menus: MenuData[] =
        response?.data?.rows
          ?.filter((menu) => menu.parent_menu_id)
          .sort((menuA, menuB) => menuA.menu_id - menuB.menu_id) ?? [];

      setData({ menus_all: menus });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<RolesData> | null = await fetchAPI(
        `roles/${roleIdParam}`
      );

      const empty = {} as RolesData;

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? empty
          : empty;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [roleIdParam, setData]);

  const saveItem = async () => {
    if (!data.name) {
      alert("Role Name cannot be empty");
      return;
    }

    try {
      const urlPath = roleIdParam ? `roles/${data?.id}` : "roles";

      const method = roleIdParam ? "PUT" : "POST";

      const saveData = {
        ...data,
        menus: data.menus_all_combined.filter(
          (menu) =>
            menu.is_view ||
            menu.is_create ||
            menu.is_edit ||
            menu.is_delete ||
            menu.is_publish ||
            menu.is_download
        ),
        menus_all: undefined,
        menus_all_combined: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.message ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const setMenusAllCombined = useCallback(() => {
    if (data?.menus_all?.length > 0) {
      const menusResponseIds: number[] = data.menus.map((menu) => menu.menu_id);

      const menusAllCombined: MenuData[] = data.menus_all.map((menu) =>
        menusResponseIds.includes(menu.menu_id)
          ? {
              ...menu,
              ...(data.menus.find(
                (menuResponse) => menuResponse.menu_id === menu.menu_id
              ) ?? ({} as MenuData)),
            }
          : {
              ...menu,
              is_view: false,
              is_create: false,
              is_edit: false,
              is_delete: false,
              is_publish: false,
            }
      );

      setData({ menus_all_combined: menusAllCombined });
    }
  }, [data.menus, data.menus_all, setData]);

  const onChangeMenuAccess = (
    menuId: number,
    actionKey: string,
    checked: boolean
  ) => {
    const menuSelected: MenuData =
      data.menus_all_combined.find((menu) => menu.menu_id === menuId) ??
      ({} as MenuData);

    const menuUpdated: MenuData = { ...menuSelected, [actionKey]: checked };

    const otherMenus: MenuData[] = data.menus_all_combined.filter(
      (menu) => menu.menu_id !== menuId
    );

    const menusUpdated: MenuData[] = [...otherMenus, menuUpdated].sort(
      (menuA, menuB) => menuA.menu_id - menuB.menu_id
    );

    setData({ menus_all_combined: menusUpdated });
  };

  const onSaveClicked = async () => {
    saveItem();
  };

  const onCancelClicked = () => {
    back();
  };

  useEffect(() => {
    fetchMenusAll();

    if (roleIdParam) {
      fetchData();
    }
  }, [fetchData, fetchMenusAll, roleIdParam]);

  useEffect(() => {
    setMenusAllCombined();
  }, [setMenusAllCombined]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Roles
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of roles data
          </p>
        </div>
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Role Name <span className="text-red-500">*</span>
            </label>
            <div className="mt-2">
              <input
                id="input-name"
                name="name"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data?.name ?? ""}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Description
            </label>
            <div className="mt-2">
              <textarea
                id="input-description"
                name="description"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data.description}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Menu Access
            </label>
            <div className="mt-2 overflow-x-auto shadow overflow-hidden rounded border-b border-gray-200">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="w-2/5 text-left py-3 px-4 font-semibold text-sm">
                      Menu
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Create
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Read
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Update
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Delete
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Publish
                    </th>
                    <th className="w-1/10 text-left py-3 px-4 font-semibold text-sm">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {data.menus_all_combined?.map((menu, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-100" : ""}
                    >
                      <td className="w-2/5 text-left py-3 px-4 text-sm">
                        {menu.menu_name}
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`create-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_create}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_create",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`read-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_view}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_view",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`update-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_edit}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_edit",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`delete-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_delete}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_delete",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`publish-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_publish}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_publish",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                      <td className="w-1/10 text-left py-3 px-4">
                        <input
                          type="checkbox"
                          name={`download-${index}`}
                          className="form-checkbox h-4 w-4 text-blue-600"
                          checked={menu.is_download}
                          onChange={
                            isView
                              ? noAction
                              : (event) => {
                                  const checked =
                                    event?.target?.checked ?? false;
                                  onChangeMenuAccess(
                                    menu.menu_id,
                                    "is_download",
                                    checked
                                  );
                                }
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {roleIdParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default RoleForm;
