import useCurrentMenu from "customhook/useCurrentMenu";
import React from "react";

const Reports = () => {
  const currentMenu = useCurrentMenu();

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return <div>This page is not ready yet</div>;
};

export default Reports;
