import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { MainRowsResponse, fetchAPI, fetchAPICustom } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import moment from "moment";
import { BusinessCategoryData } from "pages/BusinessCategory";
import { PartnerData } from "pages/Partners";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDownloadButtonStatus from "customhook/useDownloadButtonStatus";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";

export type ProductPriceData = {
  id: string;
  category_code: string;
  category_name: string;
  partner_code: string;
  partner_name: string;
  currency: string;
  base_price: number;
  service_fee: number;
  platform_fee: number;
  shipping_fee: number;
  insurance_fee: number;
  vat_type: string;
  vat: number;
  start_period: string;
  end_period: string;
  created_at: string;
  start_period_formatted: string;
  end_period_formatted: string;
  created_at_formatted: string;
  categories: BusinessCategoryData[];
  partners: PartnerData[];
};

type CategoryOtionType = {
  label: string;
  value: string;
};

type DownloadType = {
  data: [];
  title: string;
};

const ProductPrice = () => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();
  const isDownloadButtonEnable = useDownloadButtonStatus();
  const PrivilegedUsers = usePrivilegedUsers();

  const pathParent = "/cms/products-price";
  const pathView = `${pathParent}/view`;

  const [datas, setDatas] = useState<ProductPriceData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const itemsPerPageDefault = 10;

  const [categoriesOptions, setCategoriesOptions] = useState<
    CategoryOtionType[]
  >([]);

  const [dataDownload, setDataDownload] = useState<DownloadType>({
    data: [],
    title: "export-products-price",
  });

  const fetchDatas = useCallback(async (query?: any) => {
    const queryData = {
      ...query,
      sortBy: query?.sortBy ?? "id.desc",
      category_code: query?.category,
      category: undefined,
      size: query?.itemsPerPage ?? itemsPerPageDefault,
      itemsPerPage: undefined,
      page: query?.page,
    };

    if(!queryData?.search) {
      delete queryData?.filterBy;
    }

    try {
      const response: MainRowsResponse<ProductPriceData> | null =
        await fetchAPI(`products/price?${queryString.stringify(queryData)}`);

      const datas =
        response?.data?.rows?.map((data) => {
          return {
            ...data,
            start_period_formatted: moment(data.start_period).format(
              "DD MMM YYYY hh:mm"
            ),
            end_period_formatted: moment(data.end_period).format(
              "DD MMM YYYY hh:mm"
            ),
            created_at_formatted: moment(data.created_at).format(
              "DD MMM YYYY hh:mm"
            ),
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const fetchCategory = useCallback(async () => {
    try {
      const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
        await fetchAPI(`business/categories?inCode=${PrivilegedUsers.categories.toString()}`);
      const datas =
        response?.data?.rows.map((data: any) => {
          return {
            label: data.category_name,
            value: data.category_code,
          };
        }) ?? [];
      setCategoriesOptions(datas);
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const onListEventAction = async (query: any) => {
    fetchDatas(query);
  };

  const fetchDownloadData = useCallback(
    async (qString: any) => {
      try {
        const dataBody = {};
        if (qString.filterBy && qString.search) {
          if (qString.filterBy === "partner_code") {
            Object.assign(dataBody, { partnertCode: qString.search });
          } else if (qString.filterBy === "partner_name") {
            Object.assign(dataBody, { partnerName: qString.search });
          } else {
            Object.assign(dataBody, { [qString.filterBy]: qString.search });
          }
        }

        if (qString.category_code) {
          Object.assign(dataBody, { categoryCode: qString.category_code });
        }

        delete qString.filterBy;
        delete qString.search;

        const itemArr: any = [];

        await fetchAPICustom({
          url: `${process.env.REACT_APP_M_API_V1}/products-price/export`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Basic bWFya2V0cGxhY2U6QiN4YWV5eUE9eFkpe1h5X1cvWEhCM1tV",
          },
          body: JSON.stringify(dataBody),
          responseType: "text",
        })
          .then((res: any) => {
            let result: string = res as string;
            var arr = result.split("\n");

            arr.forEach((key: any, i: number) => {
              itemArr.push(key.split("^"));
            });
          })
          .catch((error: any) => {
            alert(error?.message ?? failedGetDataMessage);
          });

        if (itemArr.length > 0) {
          setDataDownload({ ...dataDownload, data: itemArr });
          return true;
        } else {
          return false;
        }
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    },
    [dataDownload]
  );

  useEffect(() => {
    fetchDatas();
    fetchCategory();
  }, [fetchCategory, fetchDatas]);

  const columns: any = [
    { label: "ID", field: "id" },
    { label: "Category Name", field: "category_name" },
    { label: "Partner ID", field: "partner_code" },
    { label: "Partner Name", field: "partner_name" },
    { label: "Currency", field: "currency" },
    { label: "Base Price", field: "base_price" },
    { label: "Service Fee", field: "service_fee" },
    { label: "Platform Fee", field: "platform_fee" },
    { label: "Shipping Fee", field: "shipping_fee" },
    { label: "Insurance Fee", field: "insurance_fee" },
    { label: "VAT Type", field: "vat_type" },
    { label: "VAT Amount", field: "vat" },
    { label: "Start Period", field: "start_period_formatted" },
    { label: "End Period", field: "end_period_formatted" },
    { label: "Created At", field: "created_at_formatted" },
  ];

  const filter = [
    {
      label: "Search By",
      field: "filterBy",
      type: "select",
      value: "",
      options: [
        { label: "Partner ID", value: "partner_code" },
        { label: "Partner Name", value: "partner_name" },
      ],
    },
    { label: "Search Data", field: "search", type: "text", value: "" },
    {
      label: "Category",
      field: "category",
      type: "select",
      value: "",
      options: categoriesOptions,
    },
    { label: "Period", field: "periode", type: "date", value: "" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "id",
      onClick: (id: string) => {
        navigate(pathView, { state: { idParam: id } });
      },
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <ListView
        columns={columns}
        rowItems={datas}
        filter={filter}
        eventAction={(query: any) => onListEventAction(query)}
        columnsAction={columnsAction}
        eventActionDownload={(val: any) => fetchDownloadData(val)}
        dataActionDownload={
          isDownloadButtonEnable
            ? {
                data: dataDownload.data,
                title: dataDownload.title,
              }
            : undefined
        }
        menu={currentMenu}
        pagination={{
          itemsPerPage: itemsPerPageDefault,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default ProductPrice;
