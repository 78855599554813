import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedDeleteMessage,
  failedGetDataMessage,
  successDeleteMessage,
} from "helpers/defaultMessage";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type RolesData = {
  id: string;
  name: string;
  description: string;
  menus: MenuData[]; // only embeded menus of current role
  menus_all: MenuData[]; // all menus from menu API
  menus_all_combined: MenuData[]; // all menus from menu API combine with embeded menus of current role
};

export type MenuData = {
  parent_menu_id?: number | null;
  menu_id: number;
  menu_name: string;
  is_view: boolean;
  is_create: boolean;
  is_edit: boolean;
  is_delete: boolean;
  is_publish: boolean;
  is_download: boolean;
};

const Roles = () => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();

  const pathParent = "/cms/roles";
  const pathView = `${pathParent}/view`;
  const pathAdd = `${pathParent}/add`;
  const pathEdit = `${pathParent}/edit`;

  const [datas, setDatas] = useState<RolesData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const limitPerPage = 10;

  const fetchDatas = useCallback(async (query?: any) => {
    const queryData = {
      sortBy: "id.asc",
      size: query?.limit ?? limitPerPage,
      page: query?.page,
    };

    try {
      const response: MainRowsResponse<RolesData> | null = await fetchAPI(
        `roles?${queryString.stringify(queryData)}`
      );

      const datas = response?.data?.rows ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const response: MainResponse<string> | null = await fetchAPI(
          `roles/${id}`,
          "DELETE"
        );

        if (response?.code === 200) {
          alert(response?.message ?? successDeleteMessage);
          fetchDatas();
        } else {
          alert(response?.message ?? failedDeleteMessage);
        }
      } catch (error: any) {
        console.error(error);
        alert(error?.message ?? failedDeleteMessage);
      }
    },
    [fetchDatas]
  );

  const onPaginationEventClicked = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const columns: any = [
    { label: "ID", field: "id" },
    { label: "Role Name", field: "name" },
    { label: "Description", field: "description" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "id",
      onClick: (roleId: string) => {
        navigate(pathView, { state: { roleIdParam: roleId } });
      },
    },
    {
      type: "edit",
      field: "id",
      onClick: (roleId: string) => {
        navigate(pathEdit, { state: { roleIdParam: roleId } });
      },
    },
    {
      type: "delete",
      field: "id",
      onClick: (roleId: string) => {
        deleteItem(roleId);
      },
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Roles
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of roles data
          </p>
        </div>
        {currentMenu.is_create && (
          <div>
            <button
              onClick={() => navigate(pathAdd)}
              className="bg-pink-600 text-white rounded-md px-5 py-2"
            >
              <span className="font-semibold">Add New</span>
            </button>
          </div>
        )}
      </div>

      <ListView
        columns={columns}
        rowItems={datas}
        columnsAction={columnsAction}
        eventAction={(query: any) => onPaginationEventClicked(query)}
        menu={currentMenu}
        pagination={{
          itemsPerPage: limitPerPage,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default Roles;
