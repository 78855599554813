const BASE_URL =
  process.env.REACT_APP_CMS_URL || "http://localhost:3001/cms/api";

// Function to handle API calls
export const fetchAPI = async <T>(
  endpoint: string,
  method?: string,
  bodyParams?: {}
): Promise<T | null> => {
  const url = `${BASE_URL}/${endpoint}`;
  const token = sessionStorage.getItem("token") ?? "";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  // console.log("TOKEN", token);

  try {
    const response = await fetch(url, {
      headers,
      method: method ?? "GET",
      body: JSON.stringify(bodyParams),
    });
    const data = await response.json();

    if (response.status === 401) {
      handleTokenExpiration();
      return null;
    }

    if (!response.ok) {
      throw new Error(data.message || "Something went wrong");
    }

    return data as T;
  } catch (error) {
    console.error("API Error:");
    throw error;
  }
};

type Options = {
  url: string;
  method: string;
  headers?: {};
  body?: string | FormData | "";
  responseType?: "json" | "text";
};

const responeType = ({ type, response }: any) => {
  if (type === "text") {
    return response.text();
  }

  if (type === "json") {
    return response.json();
  }
};

export const fetchAPICustom = async <T>(option: Options): Promise<T | null> => {
  try {
    const response = await fetch(option.url, {
      headers: option.headers,
      method: option.method ?? "GET",
      body: option.body,
    });

    const data = await responeType({ type: option.responseType, response });

    if (response.status === 401) {
      handleTokenExpiration();
      return null;
    }

    if (!response.ok) {
      throw new Error(data.message || "Something went wrong");
    }

    return data as T;
  } catch (error) {
    console.error("API Error:");
    throw error;
  }
};

// Handle token expiration (You can customize this function as per your needs)
const handleTokenExpiration = (): void => {
  console.log("Token has expired!");
  sessionStorage.removeItem("token");
  window.location.href = "/cms/login";
};

export type MainResponse<T> = {
  code: number;
  message: string;
  data: T;
};

export type MainRowsResponse<T> = {
  code: number;
  message: string;
  data: {
    rows: T[];
    count: number;
  };
};
