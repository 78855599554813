import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";

// Load environment variables from .env file

import { useNavigate } from "react-router-dom";
const REACT_APP_CMS_URL = process.env.REACT_APP_CMS_URL;
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [seePass, setSeePass] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    console.log(REACT_APP_CMS_URL);
    if (email === "" || password === "") {
      setMessage("*Please fill out your email and password to login!");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      return;
    }
    try {
      setLoading(true);

      const response = await fetch(REACT_APP_CMS_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const res = await response.json();

      setLoading(false);

      if (res.code === 200 && res?.data?.token) {
        const token = res?.data?.token;
        sessionStorage.setItem("token", res.data.token);

        const tokenDecode: any = jwtDecode(token);
        const menusResponse: any[] = tokenDecode?.menus ?? [];
        sessionStorage.setItem(
          "privileged_users",
          JSON.stringify({
            partners: tokenDecode?.partners ?? [],
            categories: tokenDecode?.categories ?? [],
            groups: tokenDecode?.groups ?? [],
          })
        );
        sessionStorage.setItem("menus", JSON.stringify(menusResponse));

        navigate("/cms/dashboard");
      } else {
        setMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      setMessage(JSON.stringify(error));
    }
  };

  return (
    <div>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-pink-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto" style={{ width: 320 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={require("../../assets/sf-logo.png")}
                  className="h-14 mr-2"
                  alt="Windster Logo"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="font-bold text-xl mt-5 text-stone-600">
                  UCMS Non Telco
                </span>
              </div>
              {message && (
                <div
                  style={{
                    paddingLeft: 0,
                    paddingTop: 10,
                    color: "red",
                  }}
                >
                  {message}
                </div>
              )}
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="email"
                      name="email"
                      type="text"
                      className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label
                      htmlFor="email"
                      className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Your Email
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="password"
                      name="password"
                      type={seePass ? "text" : "password"}
                      className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <label
                      htmlFor="password"
                      className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Your Password
                    </label>
                    <div className="absolute" style={{ right: 0, bottom: 10 }}>
                      {seePass ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                          onClick={() => setSeePass(false)}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                          onClick={() => setSeePass(true)}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div
                    className="relative pt-5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={handleLogin}
                      className="bg-pink-600 text-white rounded-md px-20 py-2 flex items-center justify-center"
                      style={{ minWidth: "200px" }}
                    >
                      {loading ? (
                        <div className="flex justify-center">
                          <div className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-solid border-current border-r-transparent">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <span className="font-semibold">Login</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
