export type DataDetailType = {
  order_id?: string;
  partner_order_id?: string;
  cust_ref_no?: string;
  cust_acc_no?: string;
  group_code?: string;
  category?: string;
  category_name?: string;
  partner_id?: string;
  partner_name?: string;
  reference_id?: string;
  mdn?: string;
  payment_account_number?: string;
  total_final_price?: string;
  order_status?: string;
  payment_method?: string;
  timestamp_fulfill?: string;
  timestamp_payment?: string;
  transaction_date?: string;
  expiry_date?: string;
  status_payment?: string;
  payment_status?: string;
  status_fulfillment?: string;
  fulfillment_status?: string;
  order_created_at?: string;
  order_updated_at?: string;
  order_created_by?: string;
  order_updated_by?: string;
  order_detail_id?: string;
  sku?: string;
  product_name?: string;
  paid_amount?: string;
  admin_fee?: string;
  client_admin_fee?: string;
  currency?: string;
  wholesale_price?: string;
  basic_price?: string;
  service_fee?: string;
  platform_fee?: string;
  shipping_fee?: string;
  insurance_fee?: string;
  vat?: string;
  total_paid_amount?: string;
  partner_sn?: string;
  customer_id?: string;
  customer_name?: string;
  bill_ref_no?: string;
  meter_number?: string;
  stand_meter?: string;
  prices_category?: string;
  meter_stand_cubication?: string;
  kwh?: string;
  bill_period?: string;
  sdp_id?: string;
  email?: string;
  type?: string;
  margin?: string;
};

export type ColumnTransactionType = {
  label: string;
  field: string;
  type?: "status" | "money";
};

export const columnInfo: ColumnTransactionType[] = [
  { label: "SKU", field: "sku" },
  { label: "Product Code", field: "product_code" },
  { label: "Product Name", field: "product_name" },
  { label: "Partner Name", field: "partner_name" },
];

export const columnCustomerInfoPPOB: ColumnTransactionType[] = [
  { label: "MDN Login", field: "mdn" },
  { label: "Email", field: "email" },
  { label: "Address", field: "address" },
];

export const columnCustomerInfo: ColumnTransactionType[] = [
  { label: "MDN Login", field: "mdn" },
  { label: "Payment Account Number", field: "payment_account_number" },
  { label: "Email", field: "email" },
  { label: "Address", field: "address" },
];

export const columnPlatformInfo: ColumnTransactionType[] = [
  { label: "App Version:", field: "payment_app_version" },
  { label: "Device Model:", field: "payment_device_model" },
  { label: "OS Version", field: "payment_os_version" },
];

export const columnPaymentInfoPPOB: ColumnTransactionType[] = [
  { label: "Bill Amount", field: "basic_price", type: "money" },
  { label: "Service Fee", field: "service_fee", type: "money" },
  { label: "Platform Fee", field: "platform_fee", type: "money" },
  { label: "Total Price", field: "total_paid_amount", type: "money" },
  { label: "Payment Method", field: "payment_method" },
  { label: "Timestamp", field: "timestamp_payment" },
  { label: "Payment Status", field: "payment_status", type: "status" },
  { label: "Partner SN", field: "partner_sn" },
];

export const columnPaymentInfo: ColumnTransactionType[] = [
  { label: "Total Price", field: "final_price", type: "money" },
  { label: "- Base Price", field: "basic_price", type: "money" },
  { label: "- MDR", field: "mdr", type: "money" },
  { label: "- VAT", field: "vat", type: "money" },
  { label: "Payment Method", field: "payment_method" },
  { label: "Payment Account Number", field: "payment_account_number" },
  { label: "Timestamp", field: "timestamp_payment" },
  { label: "Payment Status", field: "status_payment", type: "status" },
  { label: "Partner SN", field: "partner_sn" },
  { label: "SDPID", field: "sdp_id" },
];

export const columnAdditionalInfo: ColumnTransactionType[] = [
  { label: "Customer ID", field: "customer_id" },
  { label: "Customer Name", field: "customer_name" },
  { label: "BillRefNo", field: "bill_ref_no" },
  { label: "Meter Number", field: "meter_number" },
  { label: "Price Category", field: "prices_category" },
  { label: "KWH", field: "kwh" },
  { label: "Bill Period", field: "bill_period" },
];

export const columnFulfillInfo: ColumnTransactionType[] = [
  { label: "Timestamp:", field: "timestamp_fulfill" },
  { label: "Status:", field: "status_fulfillment", type: "status" },
];

export const statusPaymentList: { [key: number]: string } = {
  0: "Initial",
  1: "Initial",
  2: "Success",
  3: "Pending",
  4: "Failed",
};

export const statusFulfillmentList: { [key: number]: string } = {
  0: "Initial",
  1: "Progress",
  2: "Success",
  3: "Failed",
  4: "Pending",
  5: "Done",
};
