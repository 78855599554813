import React from "react";
import TabsComponent, { PropsTabComponent } from "components/TabsComponent";

import BulkUploadComponent from "./BulkUpload";
import ProductListComponent from "./index";
import InputManualComponent from "./InputManual";

const ProductInventories = () => {

  const dataTabs:PropsTabComponent[] = [
    {nameTab: 'Template & Bulk Upload', component: <BulkUploadComponent /> },
    {nameTab: 'Manual Input', component: <InputManualComponent /> },
    {nameTab: 'Product List Inventory', component: <ProductListComponent /> },
  ]

  return (
    <div className="bg-white">
      <TabsComponent data={dataTabs} isActiveTab={0} />
    </div>
  );
};

export default ProductInventories;
