import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedDeleteMessage,
  failedGetDataMessage,
  successDeleteMessage,
} from "helpers/defaultMessage";
import { GroupBusinessData } from "pages/GroupBusiness";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type BusinessCategoryData = {
  category_code: string;
  category_name: string;
  category_desc: string;
  group_code: string;
  active: boolean;
  active_label: string;
  groups: GroupBusinessData[];
};

const BusinessCategory = () => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();

  const pathParent = "/cms/business-category";
  const pathView = `${pathParent}/view`;
  const pathAdd = `${pathParent}/add`;
  const pathEdit = `${pathParent}/edit`;

  const [datas, setDatas] = useState<BusinessCategoryData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const limitPerPage = 10;

  const fetchDatas = useCallback(async (query?: any) => {
    const filterBy = {};
    if (query?.filterBy && query?.search) {
      Object.assign(filterBy, { [query?.filterBy]: query?.search });
    }

    delete query?.filterBy;
    delete query?.search;

    const queryData = {
      ...filterBy,
      sortBy: "category_code.asc",
      size: query?.limit ?? limitPerPage,
      page: query?.page,
    };

    try {
      const response: MainRowsResponse<BusinessCategoryData> | null =
        await fetchAPI(
          `business/categories?${queryString.stringify(queryData)}`
        );

      const datas =
        response?.data?.rows?.map((data) => {
          return {
            ...data,
            active_label: data.active ? "Active" : "Inactive",
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const deleteItem = useCallback(
    async (partnerCode: string) => {
      try {
        const response: MainResponse<string> | null = await fetchAPI(
          `business/categories/${partnerCode}`,
          "DELETE"
        );

        if (response?.code === 200) {
          alert(response?.data ?? successDeleteMessage);
          fetchDatas();
        } else {
          alert(response?.message ?? failedDeleteMessage);
        }
      } catch (error: any) {
        console.error(error);
        alert(error?.message ?? failedDeleteMessage);
      }
    },
    [fetchDatas]
  );

  const onPaginationEventClicked = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const columns: any = [
    { label: "Code", field: "category_code" },
    { label: "Name", field: "category_name" },
    { label: "Description", field: "category_desc" },
    { label: "Group", field: "group_code" },
    { label: "Status", field: "active_label" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "category_code",
      onClick: (categoryCode: string) => {
        navigate(pathView, { state: { categoryCodeParam: categoryCode } });
      },
    },
    {
      type: "edit",
      field: "category_code",
      onClick: (categoryCode: string) => {
        navigate(pathEdit, { state: { categoryCodeParam: categoryCode } });
      },
    },
    {
      type: "delete",
      field: "category_code",
      onClick: (categoryCode: string) => {
        deleteItem(categoryCode);
      },
    },
  ];

  const filter = [
    {
      label: "Filter By",
      field: "filterBy",
      type: "select",
      options: [
        { label: "By Category Code", value: "code" },
        { label: "By Category Name", value: "category_name" }
      ],
    },
    { label: "Search Data", field: "search", type: "text", placeholder: "Search Data", value: "" }
  ];


  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <div className="flex justify-between mb-5">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Business Category
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of business category
          </p>
        </div>
      </div>

      <ListView
        columns={columns}
        rowItems={datas}
        filter={filter}
        btnAddItem={pathAdd}
        columnsAction={columnsAction}
        eventAction={(query: any) => onPaginationEventClicked(query)}
        menu={currentMenu}
        pagination={{
          itemsPerPage: limitPerPage,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default BusinessCategory;
