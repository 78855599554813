import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export interface PropsTabComponent  {
    nameTab: string;
    component: React.ReactNode;
}

interface AntTabsProps {
    label: string;
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#ed1d61',
  },
});

const AntTab = styled((props: AntTabsProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#ed1d61',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#ed1d61',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#ed1d61',
    },
  }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

export default function TabsComponent(props: {data: PropsTabComponent[], isActiveTab: number}) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setValue(props.isActiveTab);
  }, [props])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
        <Box>
            <AntTabs value={value} onChange={handleChange} aria-label="tabs-component">
                {props.data.map((item:PropsTabComponent, i:number) => ( 
                    <AntTab label={item.nameTab} key={i}/>
                ))}
            </AntTabs> 
        </Box>
        {props.data.map((item:PropsTabComponent, i:number) => ( 
            <CustomTabPanel value={value} index={i}>
                {item.component}
            </CustomTabPanel>
        ))}
    </Box>
  );
}
