import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
// import useDownloadButtonStatus from "customhook/useDownloadButtonStatus";
import { MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import { GroupBusinessData } from "pages/GroupBusiness";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";

export type PartnerData = {
  partner_code: string;
  partner_name: string;
  vmd_no: string;
  secret_key: string;
  email: string;
  note: string;
  group_code: string;
  category_code: any;
  account_creation: boolean;
  account_creation_label: string;
  groups: GroupBusinessData[];
  categories_selected: any[];
  categories_selected_initiated: boolean;
  categories_name?: string;
};

// type DownloadType = {
//   data: [];
//   title: string;
// };

const Partners = () => {
  // const isDownloadButtonEnable = useDownloadButtonStatus();
  const currentMenu = useCurrentMenu();
  const PrivilegedUsers = usePrivilegedUsers();
  const navigate = useNavigate();

  const pathParent = "/cms/partners";
  const pathView = `${pathParent}/view`;
  const pathAdd = `${pathParent}/add`;
  const pathEdit = `${pathParent}/edit`;

  const [datas, setDatas] = useState<PartnerData[]>([]);
  // const [dataDownload, setDataDownload] = useState<DownloadType>({
  //   data: [],
  //   title: "export-partners",
  // });
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const limitPerPage = 10;

  const [filterData, setFilterData] = useState<any>([
    {
      label: "Partner",
      field: "partners",
      type: "select",
      selectMulti: true,
      value: "",
      options: [],
    },
  ]);

  const fetchDatas = useCallback(async (query?: any) => {
    const queryData = {
      sortBy: "partner_code.asc",
      size: query?.limit ?? limitPerPage,
      page: query?.page,
    };

    if(JSON.parse(query?.partners ?? `[]`).length > 0) {
      Object.assign(queryData, {inCode: `${JSON.parse(query?.partners ?? `[]`).toString()}`})
    }

    try {
      const response: MainRowsResponse<PartnerData> | null = await fetchAPI(
        `partners?${queryString.stringify(queryData)}`
      );

      const datas =
        response?.data?.rows.map((data) => {
          return {
            ...data,
            account_creation_label: data.account_creation ? "Yes" : "No",
            categories_names: data?.category_code
              ?.map((category: any) => category.name)
              .join(",\n"),
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const fetchPartner = async () => {
    try {
    const params = `?inCode=${PrivilegedUsers.partners.toString()}`
    const response: MainRowsResponse<PartnerData> | null = await fetchAPI(`partners${params}`);
    
    const datas = response?.data?.rows.map((data: any) => {
        return {
            label: data.partner_name,
            value: data.partner_code,
        };
        }) ?? [];
    
    filterData[0].options = datas
    setFilterData(filterData)
    } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
    }
  }

  useEffect(() => {
    fetchPartner()
  }, []);

  // TODO - khamdan - change api when BE ready
  // const fetchDownloadData = useCallback(async (qString: any) => {
  //   try {
  //     const dataBody = {};
  //     if (qString.filterBy && qString.search) {
  //       Object.assign(dataBody, { [qString.filterBy]: qString.search });
  //     }
  //     delete qString.filterBy;
  //     delete qString.search;
  //     Object.assign(dataBody, qString);

  //     const itemArr: any = [];
  //     await fetchAPICustom({
  //       url: `${process.env.REACT_APP_M_API_V1}/orders/export`,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Basic bWFya2V0cGxhY2U6QiN4YWV5eUE9eFkpe1h5X1cvWEhCM1tV",
  //       },
  //       body: JSON.stringify(dataBody),
  //       responseType: "text",
  //     }).then((res: unknown) => {
  //       let result: string = res as string;
  //       var arr = result.split("\n");

  //       arr.forEach((key: any, i: number) => {
  //         itemArr.push(key.split("^"));
  //       });
  //     });

  //     if (itemArr.length > 0) {
  //       setDataDownload({ ...dataDownload, data: itemArr });
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error: any) {
  //     alert(error?.message ?? failedGetDataMessage);
  //   }
  // }, []);

  const onPaginationEventClicked = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const columns: any = [
    { label: "ID", field: "partner_code" },
    { label: "Name", field: "partner_name" },
    { label: "Vmd", field: "vmd_no" },
    { label: "Email", field: "email" },
    { label: "Note", field: "note" },
    { label: "Group", field: "group_code" },
    { label: "Category", field: "categories_names" },
    { label: "Account Creation", field: "account_creation_label" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "partner_code",
      onClick: (partnerCode: string) => {
        navigate(pathView, { state: { partnerCodeParam: partnerCode } });
      },
    },
    {
      type: "edit",
      field: "partner_code",
      onClick: (partnerCode: string) => {
        navigate(pathEdit, { state: { partnerCodeParam: partnerCode } });
      },
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Partners
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of partner data
          </p>
        </div>
      </div>

      <ListView
        columns={columns}
        rowItems={datas}
        filter={filterData}
        columnsAction={columnsAction}
        eventAction={(query: any) => onPaginationEventClicked(query)}
        menu={currentMenu}
        // TODO - khamdan - enable when BE ready
        // eventActionDownload={(query: any) => fetchDownloadData(query)}
        // dataActionDownload={
        //   isDownloadButtonEnable
        //     ? {
        //         data: dataDownload.data,
        //         title: dataDownload.title,
        //       }
        //     : undefined
        // }
        btnAddItem={currentMenu.is_create ? pathAdd : undefined}
        pagination={{
          itemsPerPage: limitPerPage,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default Partners;
