import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useInputChange from "customhook/useInputChange";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { ProductInventoryData, statusInventoryList } from ".";
import moment from "moment";

const ProductInventoryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const idParam = location.state?.idParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/products-inventory";
  const isView = currentPath === `${pathParent}/view`;

  const noAction = () => {};

  const initialData: ProductInventoryData = useMemo(() => {
    return {
      id: "",
      no: "",
      sku: "",
      category_code: "",
      category_name: "",
      product_code: "",
      product_name: "",
      name: "",
      partner_code: "",
      partner_name: "",
      currency: "",
      wholesale_price: "",
      client_admin_fee: "",
      created_at: "",
      created_at_formatted: "",
      status: "",
      status_name: "",
      selected: false,
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<ProductInventoryData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<ProductInventoryData> | null =
        await fetchAPI(`products/inventory/${idParam}`);

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [idParam, initialData, setData]);

  const saveItem = async () => {
    try {
      const urlPath = idParam
        ? `products/inventory/${idParam}`
        : "products/inventory";
      const method = idParam ? "PUT" : "POST";

      const saveData = {
        ...data,
        created_at_formatted: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const onSaveClicked = async () => {
    saveItem();
  };

  const onCancelClicked = () => {
    back();
  };

  const onSelectStatusChange = (statusSelected: string): void => {
    setData({ status: statusSelected });
  };

  useEffect(() => {
    if (idParam) {
      fetchData();
    }
  }, [idParam, fetchData]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              SKU
            </label>
            <div className="mt-2">
              <input
                id="sku_input"
                name="sku"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.sku}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product ID
            </label>
            <div className="mt-2">
              <input
                id="product_code_input"
                name="product_code"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.product_code}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Name
            </label>
            <div className="mt-2">
              <input
                id="name_input"
                name="name"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.name}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Category ID
            </label>
            <div className="mt-2">
              <input
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.category_code}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Category Name
            </label>
            <div className="mt-2">
              <input
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.category_name}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Partner ID
            </label>
            <div className="mt-2">
              <input
                id="partner_code_input"
                name="partner_code"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.partner_code}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Partner Name
            </label>
            <div className="mt-2">
              <input
                id="partner_name_input"
                name="group_code"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.partner_name}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Currency
            </label>
            <div className="mt-2">
              <input
                id="currency_input"
                name="currency"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.currency}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Partner Fee
            </label>
            <div className="mt-2">
              <input
                id="client_admin_fee_input"
                name="client_admin_fee"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.client_admin_fee}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Upload Time
            </label>
            <div className="mt-2">
              <input
                id="created_at_input"
                name="created_at"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={moment(data.created_at).format("DD MMM YYYY hh:mm")}
                disabled={true}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Wholesale Price
            </label>
            <div className="mt-2">
              <input
                id="wholesale_price_input"
                name="wholesale_price"
                type="number"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.wholesale_price}
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
              />
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Status
            </label>
            <div className="mt-2">
              <select
                id="status_select"
                name="status"
                autoComplete="status"
                value={data?.status}
                disabled={true}
                onChange={
                  isView
                    ? noAction
                    : (event) => {
                        const statusSelected = event?.target?.value;
                        onSelectStatusChange(statusSelected);
                      }
                }
                className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {statusInventoryList?.map((status) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">{idParam ? "Update" : "Save"}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductInventoryForm;
