import React, { useEffect, useCallback, useState, useMemo, useRef, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Grid, Typography } from '@mui/material';
import ListView from 'components/ListView/ListView';
import { MainResponse, MainRowsResponse, fetchAPI, fetchAPICustom } from 'helpers/apiHelpers';
import { failedGetDataMessage, failedSaveMessage, successSaveMessage } from 'helpers/defaultMessage';
import { BusinessCategoryData } from 'pages/BusinessCategory';
import { PartnerData } from 'pages/Partners';
import useInputChange from 'customhook/useInputChange';
import queryString from 'query-string';
import { CSVLink } from 'react-csv';
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import Popup from "reactjs-popup";

type ProductInventoryType = {
    entity?: string;
    file_origin?: string;
    file_destination?: string;
    category_code?: string;
    category_name?: string;
    partner_name?: string;
    partner_code?: string;
    err_message?: string;
    error?: number;
    success?: number;
    total?: number;
    created_at?: string;
}

type ListOptsType = {
    label: string;
    value: string;
};

type FormInventoryType = {
    category_code: string;
    partner_code: string;
    filename: File | null;
}

type DownloadType = {
    data: [];
    title: string;
};

type ResponBulkType = {
    success: number;
    fail: number;
    total: number;
    errMsg: {
        line: number;
        desc: string;
    }[]
}

const responBulk:ResponBulkType = {
    "success": 0,
    "fail": 0,
    "total": 0,
    "errMsg": []
}

export default function BulkUpload() {
    const token = sessionStorage.getItem("token") ?? "";
    const [IsModal, SetIsModal] = useState(false)
    const [DataRespon, SetDataRespon] = useState<ResponBulkType>(responBulk)

    useEffect(() => {
        SetDataRespon(responBulk)
    }, [])

    const initialData:FormInventoryType = useMemo(() => {
        return {
          category_code: "",
          partner_code: "",
          filename: null
        }
    }, []);
    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);
    const [dataDownload, setDataDownload] = useState<DownloadType>({
        data: [],
        title: "export-order",
    });
    const inputFile = useRef<any>(null);

    const { data, setData, onInputChange } = useInputChange<FormInventoryType>(initialData);

    const [DataInventory, SetDataInventory] = useState<{rows: ProductInventoryType[], count: number}>({rows: [], count: 0});
    const [DataOptListCategory, SetDataOptListCategory] = useState<ListOptsType[]>([]);
    const [DataOptListPartner, SetDataOptListPartner] = useState<ListOptsType[]>([]);
    const [DataPartner, SetDataPartner] = useState<PartnerData[]>([]);
    const PrivilegedUsers = usePrivilegedUsers();

    const fetchGetData = useCallback(async (qs: any = false) => {
        try {
        const params = (qs ? queryString.stringify(qs)+'&' : '') + 'sortBy=created_at.desc' 
        const response: MainRowsResponse<ProductInventoryType> | null = await fetchAPI(
            `products/inventory/bulkHistory?${params}`
        );
        SetDataInventory({
            rows: response?.data?.rows as ProductInventoryType[] ?? [],
            count: response?.data?.count ?? 0
        })
        } catch (error: any) {
        console.error(error);
        }
    }, []);

    const fetchBulkDetail = useCallback(async (id: number) => {
        try {
        const response: MainRowsResponse<ProductInventoryType> | null = await fetchAPI(
            `products/inventory/bulkHistory/${id}`
        );
        SetIsModal(true)
        SetDataRespon({
            success: response?.data.rows[0].success as number,
            fail: response?.data.rows[0].error as number,
            total: response?.data.rows[0].total as number,
            errMsg:  JSON.parse(response?.data.rows[0].err_message ?? `[]`),
        });
        } catch (error: any) {
        console.error(error);
        }
    }, []);

    const fetchCategory = async (qs: {categoryLike: string}) => {
        try {
            const categoryCode = DataPartner.find((item:any) => item.partner_code === qs.categoryLike)?.category_code ?? []
            if(categoryCode.length > 0) {
                const arrCode: string[] = []
                categoryCode.map((x:{name: string, code: any}, _i:number) => {
                    const params:string[] = PrivilegedUsers.categories
                    if(params.includes(x.code)) {
                        return arrCode.push(x.code)
                    }
                })
                const response: MainRowsResponse<BusinessCategoryData> | null =
                    await fetchAPI(`business/categories?inCode=${arrCode.toString()}`);
                const DataOptListCategory =
                    response?.data?.rows.map((data: any) => {
                    return {
                        label: data.category_name,
                        value: data.category_code,
                    };
                    }) ?? [];
                SetDataOptListCategory(DataOptListCategory);
            } else {
                SetDataOptListCategory([]);
            }
            
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
        
    };

    const fetchPartner = async () => {
        try {
        const params = `?inCode=${PrivilegedUsers.partners.toString()}`
        const response: MainRowsResponse<PartnerData> | null = await fetchAPI(`partners${params}`);
        
        const DataOptListPartner = response?.data?.rows.map((data: any) => {
            return {
                label: data.partner_name,
                value: data.partner_code,
            };
            }) ?? [];
            SetDataOptListPartner(DataOptListPartner);
            SetDataPartner(response?.data?.rows ?? [])
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
    }

    useEffect(() => {
        fetchPartner();
    }, [])

    const fetchDownloadTemplate = async () => {
        try {
            if(!data.category_code && !data.partner_code) {
                alert('category and partner code cannot be empty');
                return false;
            }  

            const qs = queryString.stringify({
                categoryCode: data.category_code,
                categoryName: DataOptListCategory.find((item:ListOptsType, i) => item.value === data.category_code)?.label,
                partnerCode: data.partner_code,
                partnerName: DataOptListPartner.find((item:ListOptsType, i) => item.value === data.partner_code)?.label,
            })
            const response = await fetchAPICustom({
                method: 'GET',
                url: `${process.env.REACT_APP_CMS_URL}/products/inventory/bulkDownloads?${qs}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'text'
            });

            const itemArr: any = [];
            var arr = (response as string).split("\n");
            arr.forEach((key: any, i: number) => {
                itemArr.push(key.split(";"));
            });

            setDataDownload({
                data: itemArr,
                title: 'Download-Template-'+ DataOptListCategory.find((item:ListOptsType, i) => item.value === data.category_code)?.label,
            })

            setTimeout(() => {
                csvLinkRef?.current?.link.click();
            }, 1500);
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
        
    };

    useEffect(() => {
        fetchGetData();
    }, [fetchGetData])

    const handleFormSubmit = async () => {
        try {
            if(!data.category_code || !data.partner_code) {
                alert('category or partner code cannot be empty');
                return false;
            }
            
            const formData = new FormData();
            formData.append("filename", data.filename as File);
            formData.append("categoryCode", data.category_code);
            formData.append("categoryName", DataOptListCategory.find((item:ListOptsType, i) => item.value === data.category_code)?.label as string);
            formData.append("partnerCode", data.partner_code);
            formData.append("partnerName", DataOptListPartner.find((item:ListOptsType, i) => item.value === data.partner_code)?.label as string);

            const response: MainResponse<any> | null = await fetchAPICustom({
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                url: `${process.env.REACT_APP_CMS_URL}/products/inventory/bulkUploads`,
                responseType: 'json',
                body: formData,
            });
            if (response?.code === 200) {
                if(response.data.fail === 0 && response.data.success === response.data.total){
                    alert(response?.message ?? successSaveMessage);
                } else {
                    SetDataRespon(response.data);
                    SetIsModal(true)
                }
                setData(initialData);
                SetDataRespon(response.data);
                SetIsModal(true)
                inputFile.current.value = "";
                fetchGetData({categoryCode:data.category_code, partnerCode: data.partner_code });
              } else {
                alert(response?.message ?? failedSaveMessage);
              }
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
    }

    const handleChangeCategory = async (event:ChangeEvent<HTMLInputElement>) => {
        fetchCategory({categoryLike: event.target.value});
        setData({category_code: ''})
    }

    const columnsAction: any = [
        {
          type: "view",
          onClick: ({id}: {id: number}) => fetchBulkDetail(id),
        },
      ];

    const columns = [
        { label: "No", field: "no", styleTailwind: "whitespace-nowrap" },
        { label: "File Name", field: "file_origin", styleTailwind: "whitespace-nowrap" },
        { label: "Upload Timestamp", field: "created_at", styleTailwind: "whitespace-nowrap" },
    ];

    const columnsRepon = [
        { label: "line", field: "line", styleTailwind: "whitespace-nowrap" },
        { label: "desc", field: "desc", styleTailwind: "" },
    ];

    return (
        <Box>
            <CSVLink
                data={dataDownload.data ?? []}
                filename={dataDownload.title}
                ref={csvLinkRef}
            />
            <Grid justifyContent="center" container spacing={2} sx={{my:2, pb:2,  border:1, borderColor: '#ddd'}}>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth sx={{maxWidth: 250}} size={"small"}>
                        <InputLabel id="partner-name-select-label">Partner Name</InputLabel>
                        <Select
                            labelId="partner-name-select-label"
                            id="partner-name-select"
                            name="partner_code"
                            value={data.partner_code}
                            label="Partner Name"
                            onChange={(event:any) => {onInputChange(event); handleChangeCategory(event)}}
                        >
                            {DataOptListPartner.map((item, i) => (
                                <MenuItem value={item.value} key={i}>{item.label}</MenuItem>    
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth sx={{maxWidth: 250}} size={"small"}>
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            name="category_code"
                            value={data.category_code}
                            label="Category"
                            onChange={(event:any) => {onInputChange(event)}}
                        >
                            {DataOptListCategory.map((item, i) => (
                                <MenuItem value={item.value} key={i}>{item.label}</MenuItem>    
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid justifyContent="center" container spacing={2} sx={{my:2, py:2,  border:1, borderColor: '#ddd'}}>
                <Grid justifyContent="center" container spacing={2} sx={{mb:1}}>
                    <Grid item xs={6} md={2} sx={{borderBottom:1, borderColor: '#ddd'}}>
                        <Typography variant="body2" display="block">Template Product List</Typography>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Button 
                            variant="outlined" 
                            size="small" 
                            onClick={fetchDownloadTemplate}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid justifyContent="center" container spacing={2} sx={{my:2, py:2,  border:1, borderColor: '#ddd'}}>
                <Grid justifyContent="center" container spacing={2} sx={{mb:3}}>
                    <Grid item xs={6} md={3} sx={{borderBottom:1, borderColor: '#ddd'}}>
                        <div className="relative inline-block">
                            <input 
                                type="file" 
                                name="filename"
                                className="file:absolute file:right-0 
                                    file:bg-blue-500 file:text-white file:border-0
                                    file:py-1 file:px-3 file:rounded-full
                                    file:shadow-xl file:shadow-blue-500/30
                                    text-gray-600
                                "
                                ref={inputFile}
                                accept=".csv" 
                                onChange={onInputChange}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid justifyContent="center" container spacing={2} >
                    <Grid item xs={6} md={1}>
                        <Button href="#!" variant="outlined" color='success' onClick={() => handleFormSubmit()}>
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </Grid>   
            
            <ListView
                columns={columns}
                columnsAction={columnsAction}
                rowItems={DataInventory.rows}
            />

            <Popup
                open={IsModal}
                onOpen={() => SetIsModal(true)}
                onClose={() => SetIsModal(false)}
                position={'top center'}
                modal
                overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
                contentStyle={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: 16,
                    margin: 'auto',
                    marginTop: '5%',
                    width: 800,
                    minWidth: 500,
                    maxHeight: "80vh",
                    overflowY: "auto",
                    }}
                >
                    <div className="relative mt-2">
                        <div className='bg-white'>
                            <div className='border-b border-slate-200 mb-2 pb-2'>
                                <ul className='inline-block'>
                                    <li><span className='inline-block w-20'>Success</span>: {DataRespon.success}</li>
                                    <li><span className='inline-block w-20'>Fail</span>: {DataRespon.fail}</li>
                                    <li><span className='inline-block w-20'>Total</span>: {DataRespon.total}</li>
                                </ul>
                            </div>
                            <div className='font-bold text-center'>List Data Fail</div>
                            <ListView
                                columns={columnsRepon}
                                rowItems={DataRespon.errMsg}
                            />
                        </div>
                    </div>
            </Popup>
        </Box>
    )
}
