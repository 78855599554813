import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedDeleteMessage,
  failedGetDataMessage,
  successDeleteMessage,
} from "helpers/defaultMessage";
import { RolesData } from "pages/Roles";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type UserData = {
  // Response
  id: string;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  password?: string;
  active: boolean;
  roles: RolesData[];
  groups: any[];
  categories: any[];
  partners: any[];

  // UI
  password_update: boolean;
  status_label: string;
  role_names: string;

  roles_selected: any[];
  groups_selected: any[];
  categories_selected: any[];
  partners_selected: any[];

  roles_selected_initiated: boolean;
  groups_selected_initiated: boolean;
  categories_selected_initiated: boolean;
  partners_selected_initiated: boolean;
};

const Users = () => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();

  const pathParent = "/cms/users";
  const pathView = `${pathParent}/view`;
  const pathAdd = `${pathParent}/add`;
  const pathEdit = `${pathParent}/edit`;

  const [datas, setDatas] = useState<UserData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const limitPerPage = 10;

  const fetchDatas = useCallback(async (query?: any) => {
    const queryData = {
      sortBy: "id.asc",
      size: query?.limit ?? limitPerPage,
      page: query?.page,
    };

    try {
      const response: MainRowsResponse<UserData> | null = await fetchAPI(
        `users?${queryString.stringify(queryData)}`
      );

      const datas =
        response?.data?.rows?.map((data) => {
          return {
            ...data,
            status_label: data?.active ? "Active" : "Inactive",
            role_names: data?.roles?.map((role) => role.name).join(",\n"),
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const response: MainResponse<string> | null = await fetchAPI(
          `users/${id}`,
          "DELETE"
        );

        if (response?.code === 200) {
          alert(response?.message ?? successDeleteMessage);
          fetchDatas();
        } else {
          alert(response?.message ?? failedDeleteMessage);
        }
      } catch (error: any) {
        console.error(error);
        alert(error?.message ?? failedDeleteMessage);
      }
    },
    [fetchDatas]
  );

  const onPaginationEventClicked = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const columns: any = [
    { label: "ID", field: "id" },
    { label: "First Name", field: "firstname" },
    { label: "Last Name", field: "lastname" },
    { label: "Username", field: "username" },
    { label: "Email", field: "email" },
    { label: "Roles", field: "role_names" },
    { label: "Status", field: "status_label" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      field: "id",
      onClick: (userId: string) => {
        navigate(pathView, { state: { userIdParam: userId } });
      },
    },
    {
      type: "edit",
      field: "id",
      onClick: (userId: string) => {
        navigate(pathEdit, { state: { userIdParam: userId } });
      },
    },
    {
      type: "delete",
      field: "id",
      onClick: (userId: string) => {
        deleteItem(userId);
      },
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Users
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of user data
          </p>
        </div>

        {currentMenu.is_create && (
          <div>
            <button
              onClick={() => navigate(pathAdd)}
              className="bg-pink-600 text-white rounded-md px-5 py-2"
            >
              <span className="font-semibold">Add New</span>
            </button>
          </div>
        )}
      </div>

      <ListView
        columns={columns}
        rowItems={datas}
        columnsAction={columnsAction}
        eventAction={(query: any) => onPaginationEventClicked(query)}
        menu={currentMenu}
        pagination={{
          itemsPerPage: limitPerPage,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default Users;
