import useInputChange from "customhook/useInputChange";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";
import { ProductPublishData } from ".";

export type ProductPriceData = {
  id: string;
  category_code: string;
  category_name: string;
  partner_code: string;
  partner_name: string;
  currency: string;
  base_price: number;
  service_fee: number;
  platform_fee: number;
  shipping_fee: number;
  insurance_fee: number;
  vat_type: string;
  vat: number;
  start_period: string;
  end_period: string;
  created_at: string;
  start_period_formatted: string;
  end_period_formatted: string;
  created_at_formatted: string;
  is_price_popup_view_show: boolean;
  is_price_popup_edit_show: boolean;
  is_price_already_available: boolean;
};

interface PricePopupProps {
  type: "add" | "view" | "edit";
  price?: ProductPriceData;
  product?: ProductPublishData;
  onCancel: () => void;
  onSave?: (price: ProductPriceData) => void;
}

export const PricePopup = ({
  type,
  price,
  product,
  onCancel,
  onSave,
}: PricePopupProps): React.JSX.Element => {
  const isAdd = type === "add";
  const isView = type === "view";
  const isEdit = type === "edit";

  const noAction = () => {};

  const { data, setData, onInputChange } = useInputChange<ProductPriceData>(
    (price ?? { currency: "IDR" }) as ProductPriceData
  );

  const otherPricesDates =
    product?.prices
      ?.filter((priceItem) => priceItem.id !== price?.id)
      ?.map((priceItem) => {
        return {
          startDate: moment(priceItem.start_period).format("MM-DD-YYYY"),
          endDate: moment(priceItem.end_period).format("MM-DD-YYYY"),
        };
      }) ?? [];

  const disableDateBeforeStartPublish = {
    startDate: "1000-01-01",
    endDate: moment(product?.period_from)
      .subtract(1, "days")
      .format("MM-DD-YYYY"),
  };

  const disableDateAfterEndPublish = {
    startDate: moment(product?.period_to).add(1, "days").format("MM-DD-YYYY"),
    endDate: "3000-01-01",
  };

  const disbaleDates = [
    ...otherPricesDates,
    disableDateBeforeStartPublish,
    disableDateAfterEndPublish,
  ];

  const onClickSave = () => {
    if (!data?.start_period || !data?.end_period) {
      alert("Period can't be empty");
      return;
    }

    if (!data?.base_price && data?.base_price !== 0) {
      alert("Product Base Price can't be empty");
      return;
    }

    const dataUpdated = isAdd
      ? { ...data, id: 'id' }
      : data;
    onSave?.(dataUpdated);
  };

  return (
    <div>
      <div className="sm:grid sm:grid-cols-1 sm:gap-4">
        <div className="mt-4 grid gap-y-8">
          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Period
            </label>
            <Datepicker
              primaryColor={"blue"}
              useRange={false}
              asSingle={false}
              disabled={isView}
              readOnly={true}
              displayFormat={"DD MMM YYYY"}
              separator={"-"}
              popoverDirection="down"
              disabledDates={disbaleDates}
              value={{
                startDate: data.start_period,
                endDate: data.end_period,
              }}
              onChange={(value) =>
                isView
                  ? noAction
                  : setData({
                      start_period: value?.startDate?.toString(),
                      end_period: value?.endDate?.toString(),
                    })
              }
              containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500"
            />
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Base Price
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="base_price"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.base_price}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Service Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="service_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.service_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Platform Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="platform_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.platform_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Shipping Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="shipping_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.shipping_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Insurance Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="insurance_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.insurance_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              VAT
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="vat"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.vat}
              />
            </div>
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancel}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onClickSave}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">{isEdit ? "Update" : "Save"}</span>
          </button>
        </div>
      )}
    </div>
  );
};
