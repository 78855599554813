import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAPI } from "../../helpers/apiHelpers";
import TransactionDetailPPOBComponent from "components/Transaction/TransactionDetailPPOBComponent"
import TransactionDetailComponent from "components/Transaction/TransactionDetailComponent"
import { DataDetailType } from "components/Transaction/TransactionType";

const OrderDetail = () => {
  const params = useParams();
  const [data, setData] = useState<DataDetailType[]>([]);

  const getOrderDetail = useCallback(async () => {
    const res: any = await fetchAPI(`orders/${params.id}/${params.group}`);
    if (res.status === 1) {
      setData(res.data);
    }
  }, [params.group, params.id])

 useEffect(() => {
  getOrderDetail()
 }, [getOrderDetail])

  return (
    <Fragment>
      { params.group === process.env.REACT_APP_GROUP_PPOB ? (
        <TransactionDetailPPOBComponent data={data} isRouteBack="/cms/orders"  />
      ) : (
        <div>
          <TransactionDetailComponent data={data} isRouteBack="/cms/orders"  />
        </div>
      )}
    </Fragment>
  );
};

export default OrderDetail;
