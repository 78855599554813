import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useInputChange from "customhook/useInputChange";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { ProductPriceData } from ".";
import { PartnerData } from "pages/Partners";
import { BusinessCategoryData } from "pages/BusinessCategory";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";

const ProductPriceSettingForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const idParam = location.state?.idParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/products-price";
  const isView = currentPath === `${pathParent}/view`;

  const noAction = () => {};

  const initialData: ProductPriceData = useMemo(() => {
    return {
      id: "",
      category_code: "",
      category_name: "",
      partner_code: "",
      partner_name: "",
      currency: "IDR",
      base_price: 0,
      service_fee: 0,
      platform_fee: 0,
      shipping_fee: 0,
      insurance_fee: 0,
      vat_type: "",
      vat: 0,
      start_period: moment().format("YYYY-MM-DD"),
      end_period: moment().format("YYYY-MM-DD"),
      created_at: "",
      start_period_formatted: "",
      end_period_formatted: "",
      created_at_formatted: "",
      categories: [],
      partners: [],
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<ProductPriceData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response: MainRowsResponse<BusinessCategoryData> | null =
        await fetchAPI("business/categories?sortBy=category_name.asc");

      const categories = response?.data?.rows ?? [];

      setData({ categories: categories });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchPartners = useCallback(async () => {
    try {
      const response: MainRowsResponse<PartnerData> | null = await fetchAPI(
        "partners?sortBy=partner_code.asc"
      );

      const partners = response?.data?.rows ?? [];

      setData({ partners: partners });
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [setData]);

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<ProductPriceData> | null =
        await fetchAPI(`products/price?id=${idParam}`);
      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;
      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [idParam, initialData, setData]);

  const saveItem = async () => {
    try {
      const urlPath = idParam ? `products/price/${data?.id}` : "products/price";

      const method = idParam ? "PUT" : "POST";

      const defaultCategoryCode =
        data.categories?.length > 0 ? data.categories[0].category_code : "";

      const categoryCode =
        data.category_code?.length > 0
          ? data.category_code
          : defaultCategoryCode;

      const defaultPartnerCode =
        data.partners?.length > 0 ? data.partners[0].partner_code : "";

      const partnerCode =
        data.partner_code?.length > 0 ? data.partner_code : defaultPartnerCode;

      const saveData = {
        ...data,
        category_code: categoryCode,
        partner_code: partnerCode,
        categories: undefined,
        partners: undefined,
        vat_type: undefined,
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.data ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchPartners();

    if (idParam) {
      fetchData();
    }
  }, [idParam, fetchData, fetchCategories, fetchPartners]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Category
            </label>
            <div className="mt-2">
              <select
                id="select_category"
                value={data?.category_code}
                onChange={
                  isView
                    ? noAction
                    : (event) => {
                        const categoryCodeSelected = event?.target?.value;
                        setData({ category_code: categoryCodeSelected });
                      }
                }
                disabled={isView}
                className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {data?.categories?.map((categories) => (
                  <option
                    key={categories.category_code}
                    value={categories.category_code}
                  >
                    {categories.category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Partner
            </label>
            <div className="mt-2">
              <select
                id="select_partner"
                value={data?.partner_code}
                onChange={
                  isView
                    ? noAction
                    : (event) => {
                        const partnerCodeSelected = event?.target?.value;
                        setData({ partner_code: partnerCodeSelected });
                      }
                }
                disabled={isView}
                className="block w-full rounded-md border-0 py-2 px-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {data?.partners?.map((partner) => (
                  <option
                    key={partner.partner_code}
                    value={partner.partner_code}
                  >
                    {`${partner.partner_code} - ${partner.partner_name}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Base Price
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="base_price"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.base_price}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Service Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="service_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.service_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Platform Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="platform_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.platform_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Shipping Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="shipping_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.shipping_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Service Fee
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="insurance_fee"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.insurance_fee}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              VAT
            </label>
            <div className="mt-2 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                {data.currency}
              </span>
              <input
                name="vat"
                type="input"
                className="pl-11 block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                disabled={isView}
                value={data.vat}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Period
            </label>
            <Datepicker
              primaryColor={"blue"}
              useRange={false}
              asSingle={false}
              disabled={isView}
              value={{
                startDate: data.start_period,
                endDate: data.end_period,
              }}
              onChange={(value) =>
                isView
                  ? noAction
                  : setData({
                      start_period:
                        value?.startDate?.toString() ??
                        moment().format("YYYY-MM-DD"),
                      end_period:
                        value?.endDate?.toString() ??
                        moment().format("YYYY-MM-DD"),
                    })
              }
              containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500"
            />
          </div>
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={back}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={saveItem}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">{idParam ? "Update" : "Save"}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductPriceSettingForm;
