/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";
import PaginationView from "./PaginationView";
import { CSVLink } from "react-csv";
import { Menu } from "customhook/useCurrentMenu";
import Select from "react-select";

export interface PropsListView {
  columns: {
    label: string;
    field: string;
    styleTailwind?: string;
  }[];
  columnSelection?: {
    type: "radio" | "checkbox";
    idField: string;
    selectedField: string;
    onChange: Function;
  };
  columnsAction?: {
    type: "view" | "edit" | "delete";
    onClick: Function;
    field: string;
  }[];
  footers?: string[];
  rowItems: any;
  filter?: {
    label: string;
    field: string;
    type: "text" | "select" | "date" | string;
    value?: string | number;
    valueSelect?: { label: string; value: string | number };
    valueDate?: { startDate: Date; endDate: Date };
    selectMulti?: boolean;
    placeholder?: string;
    styleTailwind?: string;
    options?: {
      label: string;
      value: string | number;
    }[];
  }[];
  eventAction?: Function;
  eventActionDownload?: Function;
  dataActionDownload?: {
    data: string[][];
    title: string;
  };
  btnAddItem?: string;
  pagination?: {
    itemsPerPage?: number;
    countRows?: number;
  };
  menu?: Menu;
  isLoading?: boolean;
}

const ListView = (props: PropsListView) => {
  const navigate = useNavigate();

  const [filterState, setfilterState] = useState<PropsListView["filter"]>(
    props.filter
  );

  const [filterDate, setfilterDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [itemsPerPage, setItemsPerPage] = useState(
    props?.pagination?.itemsPerPage ?? 0
  );

  useEffect(() => {
    const objectFilter = setObjectFilter();
    if (Object.keys(objectFilter).length === 0) {
      setfilterState(props.filter);
    }
  }, [props.filter]);

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleValueChange = (type: string, field: string, newValue: any) => {
    setfilterDate(newValue);
    filterState?.forEach((row) => {
      if (row["type"] === type && row["field"] === field) {
        if (row["type"] === "select") {
          const arrString: string[] = [];
          if (row["selectMulti"] === true) {
            newValue?.forEach((item: any) => {
              arrString.push(item.value);
            });
            row.value = JSON.stringify(arrString);
            row.valueSelect = newValue
          } else {
            row.value = newValue.value;
            row.valueSelect = newValue;
          }
        } else if (row["type"] === "date") {
          row.valueDate = newValue;
        } else {
          row.value = newValue;
        }
      }
    });
    setfilterState(filterState);
  };

  const setObjectFilter = () => {
    const objectFilter = {};
    filterState?.forEach((row) => {
      const item: any = row;
      if (item.value) {
        Object.assign(objectFilter, { [item.field]: item.value });
      }

      if (item.valueSelect) {
        const arrString: string[] = [];
        if (item.selectMulti === true) {
          item.valueSelect?.forEach((item: any) => {
            arrString.push(item.value);
          });
          Object.assign(objectFilter, { [item.field]: JSON.stringify(arrString) });
        } else {
          Object.assign(objectFilter, { [item.field]: item.valueSelect.value });
        }
      }
      if (item.valueDate) {
        Object.assign(objectFilter, item.valueDate);
      }
    });
    return objectFilter;
  };

  const handleSubmitFilter = (pagination: any = false) => {
    const itemsPerPageFilter = parseInt(
      filterState
        ?.find((filter) => filter.field === "itemsPerPage")
        ?.value?.toString() ?? ""
    );

    const itemsPerPage = !isNaN(itemsPerPageFilter ?? "")
      ? itemsPerPageFilter
      : props?.pagination?.itemsPerPage ?? 0;

    setItemsPerPage(itemsPerPage);

    const objectFilter = setObjectFilter();
    if (pagination && pagination.limit && pagination.offset !== "") {
      Object.assign(objectFilter, pagination);
    }
    props.eventAction?.(objectFilter);
  };

  const handleDonwload = async () => {
    const objectFilter = setObjectFilter();
    const resData = await props.eventActionDownload?.(objectFilter);

    if (resData) {
      setTimeout(() => {
        csvLinkRef?.current?.link.click();
      }, 1500);
    }
  };

  const DataNotFound = (value: number) => {
    // eslint-disable-next-line eqeqeq
    if (value == 0) {
      return (
        <tr>
          <td
            colSpan={100}
            className="border border-slate-100 p-5 text-center uppercase text-gray-600"
          >
            Data Not Available
          </td>
        </tr>
      );
    }
  };

  const Loading = (props: { isLoading?: boolean }) => {
    return props.isLoading ? (
      <tr>
        <td
          colSpan={100}
          className="border border-slate-100 p-5 text-center uppercase text-gray-600"
        >
          <div className="flex items-center justify-center">
            <div className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-black border-r-transparent"></div>
            <span className="ml-2">Loading...</span>
          </div>
        </td>
      </tr>
    ) : (
      <></>
    );
  };

  return (
    <Fragment>
      <CSVLink
        data={props.dataActionDownload?.data ?? []}
        filename={props.dataActionDownload?.title ?? "export-data.csv"}
        ref={csvLinkRef}
      />
      <div className="pb-5">
        {filterState && (
          <>
            <div className="flex flex-wrap mb-4">
              {filterState?.map((item: any, _i) => (
                <div
                  className={
                    item.styleTailwind
                      ? item.styleTailwind
                      : `w-full md:w-1/5 pr-3`
                  }
                  key={_i}
                >
                  {item.type === "text" && (
                    <>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {item.label}
                      </label>
                      <input
                        key={_i}
                        type={item.type}
                        placeholder={item.placeholder}
                        value={item?.value}
                        onChange={(val) =>
                          handleValueChange(
                            item.type,
                            item.field,
                            val.target.value
                          )
                        }
                        className="appearance-none block w-full bg-gray-0 text-gray-700 border border-gray-300 rounded-lg py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </>
                  )}

                  {item.type === "select" && (
                    <>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {item.label}
                      </label>
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) =>
                          handleValueChange(
                            item.type,
                            item.field,
                            item?.selectMulti ? val : val
                          )
                        }
                        isMulti={item?.selectMulti}
                        options={item?.options}
                        // defaultValue={item?.valueSelect}
                        value={item?.valueSelect}
                      />
                    </>
                  )}

                  {item.type === "date" && (
                    <>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {item.label}
                      </label>
                      <Datepicker
                        primaryColor={"blue"}
                        useRange={false}
                        asSingle={false}
                        value={item.valueDate}
                        onChange={(val) =>
                          handleValueChange(item.type, item.field, val)
                        }
                        containerClassName="relative border border-gray-300 rounded-lg focus:bg-white focus:border-gray-500 p-[1px]"
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        )}

        <div className="flex flex-between">
          {filterState?.length !== 0 && filterState?.length !== undefined && (
            <button
              onClick={handleSubmitFilter}
              className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded"
            >
              Search
            </button>
          )}

          <div className="w-full">
            <div className="flex justify-end">
              {(props.menu?.is_download === undefined ||
                props.menu?.is_download === true) &&
                props.dataActionDownload && (
                  <button
                    onClick={handleDonwload}
                    className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded flex flex-between mr-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Download CSV
                  </button>
                )}

              {(props.menu?.is_create === undefined ||
                props.menu?.is_create === true) && 
                props.btnAddItem && (
                <button
                  onClick={() => navigate(`${props.btnAddItem}`)}
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex flex-between mr-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Add New
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="border-collapse border border-gray-200 w-full">
          <thead>
            <tr className="bg-gray-100 border-x border-gray-200">
              {props.columnSelection && (
                <th className="border-y border-gray-200 p-2 text-gray-600 text-left">
                  Select
                </th>
              )}
              {props.columns?.map((header, _i) => (
                <th
                  key={_i}
                  className={`${header.styleTailwind} border-y border-gray-200 px-3 py-4 text-gray-600 text-left whitespace-nowrap`}
                >
                  {header.label}
                </th>
              ))}
              {props.columnsAction && (
                <th className="border-y border-gray-200 p-2 text-gray-600 text-left">
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.rowItems?.map((rowItem: any, _i: number) => (
              <tr className={_i % 2 === 1 ? "bg-gray-50" : ""} key={_i}>
                {props.columnSelection && (
                  <td className="border-y border-slate-100 px-3 py-2">
                    <div className="text-center">
                      {props.columnSelection.type === "radio" ? (
                        <input
                          type="radio"
                          checked={rowItem[props.columnSelection.selectedField]}
                          className="h-5 w-5"
                          onChange={() =>
                            props.columnSelection?.onChange(rowItem)
                          }
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={rowItem[props.columnSelection.selectedField]}
                          className="h-5 w-5"
                          onChange={() =>
                            props.columnSelection?.onChange(rowItem)
                          }
                        />
                      )}
                    </div>
                  </td>
                )}
                {props.columns?.map((_header, _i) => (
                  <td
                    key={_i}
                    className={`${_header.styleTailwind} border-y border-slate-100 px-3 py-2`}
                  >
                    {rowItem[_header.field]}
                  </td>
                ))}

                {props.columnsAction?.length && (
                  <td
                    className="border-y border-slate-100 px-3 py-2"
                    key={`action-${_i}`}
                  >
                    <div className="flex">
                      {props.columnsAction?.map((itemAction, _i) => (
                        <>
                          {(props.menu?.is_view === undefined ||
                            props.menu?.is_view === true) &&
                            itemAction?.type === "view" && (
                              <button
                                className="bg-bluex-500 text-blue-700 border border-blue-600 p-1 px-2 rounded mr-2"
                                onClick={() =>
                                  itemAction.onClick(
                                    itemAction.field
                                      ? rowItem[itemAction.field]
                                      : rowItem
                                  )
                                }
                              >
                                <svg
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                </svg>
                              </button>
                            )}

                          {(props.menu?.is_edit === undefined ||
                            props.menu?.is_edit === true) &&
                            itemAction?.type === "edit" && (
                              <button
                                className="bg-amberx-500 text-amber-700 border border-amber-600 p-1 px-2 rounded mr-2"
                                onClick={() =>
                                  itemAction.onClick(
                                    itemAction.field
                                      ? rowItem[itemAction.field]
                                      : rowItem
                                  )
                                }
                              >
                                <svg
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                  />
                                </svg>
                              </button>
                            )}

                          {(props.menu?.is_delete === undefined ||
                            props.menu?.is_delete === true) &&
                            itemAction?.type === "delete" && (
                              <button
                                className="bg-rosex-500 text-rose-700 border border-rose-600 p-1 px-2 rounded mr-2"
                                onClick={() => {
                                  if (
                                    window.confirm("Are you sure?") === true
                                  ) {
                                    itemAction.onClick(
                                      itemAction.field
                                        ? rowItem[itemAction.field]
                                        : rowItem
                                    );
                                  }
                                }}
                              >
                                <svg
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            )}
                        </>
                      ))}
                    </div>
                  </td>
                )}
              </tr>
            ))}

            {!props.isLoading && DataNotFound(props.rowItems?.length)}

            <Loading
              isLoading={props.isLoading && props.rowItems?.length <= 0}
            />
          </tbody>
          {props.footers && props.rowItems?.length > 0 && (
            <tfoot>
              <tr className="border-y border-gray-200 bg-gray-100">
                {props.footers?.map((text) => (
                  <th className="p-2 text-gray-600 text-left px-3 py-2">
                    {text}
                  </th>
                ))}
                {props.columnSelection && <th />}
                {props.columnsAction && props.columnsAction?.map(() => <th />)}
              </tr>
            </tfoot>
          )}
        </table>
      </div>

      {props.pagination && (
        <PaginationView
          itemsPerPage={itemsPerPage}
          countRows={props.pagination?.countRows ?? 0}
          valuePaginate={(val: { limit: number; offset: number }) =>
            handleSubmitFilter(val)
          }
        />
      )}
    </Fragment>
  );
};

export default ListView;
