import { ChangeEvent, useCallback, useState } from "react";

type GenericDataType = {
  [key: string]: string | boolean | number | File | any;
};

type UseInputChangeHook<T> = {
  data: T;
  setData: (data: Partial<T>) => void;
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const useInputChange = <T extends GenericDataType>(
  initialData: T
): UseInputChangeHook<T> => {
  const [data, setCurrentData] = useState<T>(initialData);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { name, value, type } = event.target;
      
      const getValForm = () => {
        if (type === "checkbox") {
          return (event.target as HTMLInputElement).checked
        } 
        else if (type === "file") {
          return (event.target as HTMLInputElement).files?.[0]
        } 
        else {
          return value
        }
      }

      setCurrentData((prevData: T) => ({
        ...prevData,
        [name]: getValForm(),
      }));
    },
    []
  );

  const setData = useCallback((data: Partial<T>) => {
    setCurrentData((prevData: T) => ({
      ...prevData,
      ...data,
    }));
  }, []);

  return { data, setData, onInputChange };
};

export default useInputChange;
