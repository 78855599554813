/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fetchAPI,
  fetchAPICustom,
  MainRowsResponse,
} from "../../helpers/apiHelpers";
import queryString from "query-string";
import ListView from "components/ListView/ListView";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import useCurrentMenu from "customhook/useCurrentMenu";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import useDownloadButtonStatus from "customhook/useDownloadButtonStatus";

type OrderItemType = {
  category: string | null;
  category_name: string | null;
  mdn: string | null;
  order_id: string | null;
  order_ids: string | null;
  partner_id: string | null;
  partner_order_id: string | null;
  payment_account_number: string | null;
  payment_method: string | null;
  status_fulfillment: string | null;
  status_payment: string | null;
  timestamp_fulfill: string | null;
  timestamp_payment: string | null;
  total_final_price: string | null;
  transaction_date: string | null;
};

type OrderType = {
  rows?: OrderItemType[];
  count?: number;
};

type CategoryListOptsType = {
  label: string;
  value: string;
};

type DownloadType = {
  data: [];
  title: string;
};

function Orders() {
  const isDownloadButtonEnable = useDownloadButtonStatus();
  const currentMenu = useCurrentMenu();
  const PrivilegedUsers = usePrivilegedUsers();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const [data, setData] = useState<OrderType>({ rows: [], count: 0 });
  const [QFilter, setQFilter] = useState<any>({});
  const [filterData, setFilterData] = useState<any>([
    {
      label: "Filter By",
      field: "filterBy",
      type: "select",
      options: [
        { label: "Order ID", value: "orderId" },
        { label: "MDN", value: "mdn" }
      ],
    },
    { label: "Search Data", field: "search", type: "text", placeholder: "Search Data", value: "" },
    {
      label: "Partner ID",
      field: "partners",
      type: "select",
      selectMulti: true,
      value: "",
      options: [],
    },
    {
      label: "Category",
      field: "category",
      type: "select",
      selectMulti: true,
      value: "",
      options: [],
    },
    { label: "Periode", field: "periode", type: "date", value: "" },
  ]);

  const [dataDownload, setDataDownload] = useState<DownloadType>({
    data: [],
    title: "export-order",
  });
  const [categoryListOpts, setCategoryListOpts] = useState<
    CategoryListOptsType[]
  >([]);
  const [partnerListOpts, setPartnerListOpts] = useState<
    CategoryListOptsType[]
  >([]);

  const fetchGetData = useCallback(async (qs: any = false) => {
    try {
      setLoading(true);
      const filterOder = {};
      if (qs.filterBy && qs.search) {
        Object.assign(filterOder, { [qs.filterBy]: qs.search });
      }
      if (JSON.parse(qs.category ?? "[]").length > 0) {
        Object.assign(filterOder, {
          category: JSON.parse(qs.category).toString(),
        });
      }

      if (JSON.parse(qs.partners ?? "[]").length > 0) {
        Object.assign(filterOder, {
          partners: JSON.parse(qs.partners).toString(),
        });
      }

      delete qs.filterBy;
      delete qs.search;
      delete qs.category;
      delete qs.partners;

      Object.assign(filterOder, qs);

      const qsVal =
        Object.keys(filterOder).length > 0
          ? `?${queryString.stringify(filterOder)}`
          : "";
      const response: MainRowsResponse<OrderType> | null = await fetchAPI(
        `orders${qsVal}`
      );
      setData({
        rows: (response?.data.rows as OrderItemType[]) ?? [],
        count: response?.data.count,
      });
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
      setLoading(false);
    }
  }, []);

  const fetchDownloadData = useCallback(async (qString: any) => {
    try {
      const dataBody = {};
      if (qString.filterBy && qString.search) {
        Object.assign(dataBody, { [qString.filterBy]: qString.search });
      }

      if (Object.keys(PrivilegedUsers).length) {
        Object.assign(dataBody, {
          partners: `{${PrivilegedUsers.partners.toString()}}`,
          groups: `{${PrivilegedUsers.groups.toString()}}`,
        });
      }

      qString.partners = qString.partners
        ? `{${JSON.parse(qString.partners).toString()}}`
        : PrivilegedUsers.partners.length > 0
        ? `{${PrivilegedUsers.partners.toString()}}`
        : "";

      qString.category = qString.category
        ? `{${JSON.parse(qString.category).toString()}}`
        : PrivilegedUsers.categories.length > 0
        ? `{${PrivilegedUsers.categories.toString()}}`
        : "";

      delete qString.filterBy;
      delete qString.search;
      Object.assign(dataBody, qString);

      const itemArr: any = [];
      await fetchAPICustom({
        url: `${process.env.REACT_APP_M_API_V1}/orders/export`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic bWFya2V0cGxhY2U6QiN4YWV5eUE9eFkpe1h5X1cvWEhCM1tV",
        },
        body: JSON.stringify(dataBody),
        responseType: "text",
      }).then((res: unknown) => {
        let result: string = res as string;
        var arr = result.split("\n");

        arr.forEach((key: any, i: number) => {
          itemArr.push(key.split("^"));
        });
      });

      if (itemArr.length > 0) {
        setDataDownload({ ...dataDownload, data: itemArr });
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const fetchCategory = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.categories).length) {
      try {
        const qs = `?inCode=` + PrivilegedUsers?.categories.toString();
        const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
          await fetchAPI(`business/categories${qs}`);
        const datas =
          response?.data?.rows.map((data: any) => {
            return {
              label: data.category_name,
              value: data.category_code,
            };
          }) ?? [];
        filterData[3].options = datas
        setFilterData(filterData)

        setCategoryListOpts(datas);
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    }
  }, []);

  const fetchPartner = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.categories).length) {
      try {
        const qs = `?inCode=` + PrivilegedUsers?.partners.toString();
        const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
          await fetchAPI(`partners${qs}`);
        const datas =
          response?.data?.rows.map((data: any) => {
            return {
              label: data.partner_name,
              value: data.partner_code.trim(),
            };
          }) ?? [];
        filterData[2].options = datas
        setFilterData(filterData)

        setPartnerListOpts(datas);
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    }
  }, []);

  useEffect(() => {
    fetchPartner();
    fetchCategory();
  }, [fetchCategory, fetchPartner]);

  useEffect(() => {
    if(location.state) {
      let cloneQs = Object.assign({}, location.state);
      filterData[0].valueSelect = filterData[0].options.filter((word: { value: string; }) => word.value === location.state.filterBy)[0]
      filterData[1].value = `${location?.state?.search ?? ''}`

      filterData[3].valueSelect = categoryListOpts.filter((word:any) => {
        return JSON.parse(location?.state?.category ?? '[]').includes(word.value)
      });
      filterData[2].valueSelect = partnerListOpts.filter((word:any) => {
        return JSON.parse(location?.state?.partners ?? '[]').includes(word.value)
      });
      filterData[4].valueDate = {
        startDate: location?.state?.startDate,
        endDate: location?.state?.endDate
      };

      setFilterData(filterData)

      fetchGetData(cloneQs);
      
      window.history.replaceState({}, '')
    } else {
      fetchGetData({ limit: 10 });
    }
  }, [fetchGetData, categoryListOpts, location]);

  const handleEventAction = async (qs: any) => {
    qs = { ...qs, limit: 10 };
    let cloneQs = Object.assign({}, qs);
    setQFilter(cloneQs);
    fetchGetData(qs);
  };

  const columns = [
    { label: "No", field: "no" },
    { label: "Category", field: "category_name", styleTailwind: "whitespace-nowrap" },
    { label: "Payment Method", field: "payment_method", styleTailwind: "whitespace-nowrap" },
    { label: "Order ID", field: "partner_order_id", styleTailwind: 'whitespace-nowrap' },
    { label: "Partner ID", field: "partner_id", styleTailwind: "whitespace-nowrap" },
    { label: "MDN", field: "mdn", styleTailwind: "whitespace-nowrap" },
    { label: "Transaction Date", field: "transaction_date", styleTailwind: "whitespace-nowrap" },
    { label: "Total Final Price", field: "total_final_price", styleTailwind: "whitespace-nowrap" },
    { label: "Timestamp Payment", field: "timestamp_payment", styleTailwind: "whitespace-nowrap" },
    { label: "Status Payment", field: "status_payment", styleTailwind: "whitespace-nowrap" },
    { label: "Status Fulfillment", field: "status_fulfillment", styleTailwind: "whitespace-nowrap" },
    { label: "Timestamp Fulfill", field: "timestamp_fulfill", styleTailwind: "whitespace-nowrap" },
  ];

  const columnsAction: any = [
    {
      type: "view",
      onClick: ({order_id, group_code}: {order_id: number, group_code: string}) => navigate(`/cms/order-detail/${order_id}/${group_code}`, {state: QFilter}),
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div>
      <BreadcrumbComponent active={"Orders"} />
      <ListView
        isLoading={isLoading}
        columns={columns}
        rowItems={data.rows}
        filter={filterData}
        eventAction={(val: any) => handleEventAction(val)}
        eventActionDownload={(val: any) => fetchDownloadData(val)}
        menu={currentMenu}
        columnsAction={columnsAction}
        dataActionDownload={
          isDownloadButtonEnable
            ? {
                data: dataDownload.data,
                title: dataDownload.title,
              }
            : undefined
        }
        pagination={{
          itemsPerPage: 10,
          countRows: data?.count,
        }}
      />
    </div>
  );
}

export default Orders;
