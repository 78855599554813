import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import useInputChange from 'customhook/useInputChange';
import { MainResponse, MainRowsResponse, fetchAPI } from 'helpers/apiHelpers';
import { failedGetDataMessage, failedSaveMessage, successSaveMessage } from 'helpers/defaultMessage';
import { BusinessCategoryData } from 'pages/BusinessCategory';
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { PartnerData } from 'pages/Partners';

type ListOptsType = {
    label: string;
    value: string;
};

type FormType = {
    partner_code?: string;
    category_code?: string;
    sku?: string;
    type?: number;
    product_code?:  string;
    payee_code?:  string;
    name?:  string;
    area?:  string;
    currency?:  string;
    client_admin_fee?: number;
    wholesale_price?: number;
    status?: number;
}

type ValidationForm = {
    partner_code?: boolean;
    category_code?: boolean;
    name?: boolean,
    sku?: boolean,
    product_code?: boolean,
    area?: boolean,
    currency?: boolean,
    client_admin_fee?: boolean,
    wholesale_price?: boolean,
}

const LABEL_REQUIRED = 'Field Is Required'

export default function InputManual() {
    const PrivilegedUsers = usePrivilegedUsers();
    const initialData:FormType = useMemo(() => {
        return {
            partner_code: "",
            category_code: "",
            sku: "",
            type: 0,
            product_code: "",
            payee_code: "",
            name: "",
            area: "",
            currency: "IDR",
            client_admin_fee: 0,
            wholesale_price: 0,
            status: 0
        }
    }, []);

    const initialDataValidation:ValidationForm = useMemo(() => {
        return {
            partner_code: false,
            category_code: false,
            name: false,
            sku: false,
            product_code: false,
            area: false,
            currency: false,
            client_admin_fee: false,
            wholesale_price: false,
        }
    }, []);

    const { data, setData, onInputChange } = useInputChange<FormType>(initialData);

    const [DataCategory, SetDataCategory] = useState<ListOptsType[]>([]);
    const [DataPartner, SetDataPartner] = useState<ListOptsType[]>([]);
    const [DataPartnerList, SetDataPartnerList] = useState<PartnerData[]>([]);
    const [DataValidation, SetDataValidation] = useState<ValidationForm>(initialDataValidation);

    const fetchCategory = async (qs: {categoryLike: string}) => {
        const categoryCode = DataPartnerList.find((item:any) => item.partner_code === qs.categoryLike)?.category_code ?? []
        if(categoryCode.length > 0) {
            const arrCode: string[] = []
            categoryCode.map((x:{name: string, code: any}, _i:number) => {
                const params:string[] = PrivilegedUsers.categories
                if(params.includes(x.code)) {
                    return arrCode.push(x.code)
                }
            })
            const response: MainRowsResponse<BusinessCategoryData> | null =
                await fetchAPI(`business/categories?inCode=${arrCode.toString()}`);
            const DataOptListCategory =
                response?.data?.rows.map((data: any) => {
                return {
                    label: data.category_name,
                    value: data.category_code,
                };
                }) ?? [];
                SetDataCategory(DataOptListCategory);
        } else {
                SetDataCategory([]);
        }
        
    };

    const fetchPartner = async () => {
        try {
        const params = `?inCode=${PrivilegedUsers.partners.toString()}`
        const response: MainRowsResponse<PartnerData> | null =
            await fetchAPI(`partners${params}`);
        const datas =
            response?.data?.rows.map((data: any) => {
            return {
                label: data.partner_name,
                value: data.partner_code,
            };
            }) ?? [];
            SetDataPartner(datas);
            SetDataPartnerList(response?.data?.rows ?? [])
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
        
    }

    useEffect(() => {
        fetchPartner();
    }, [])

    const handleFormSubmit = async () => {
        try {
            SetDataValidation({
                category_code: data.category_code?.length === 0 ? true : false,
                partner_code: data.partner_code?.length === 0 ? true : false,
                name: data.name?.length === 0 ? true : false,
                sku: data.sku?.length === 0 ? true : false,
                product_code: data.product_code?.length === 0 ? true : false,
                area: data.area?.length === 0 ? true : false,
                currency: data.currency?.length === 0 ? true : false,
                client_admin_fee: Number(data.client_admin_fee) === 0 ? true : false,
                wholesale_price: String(data.wholesale_price).length === 0 ? true : false
            })

            if(data.sku === '' || 
                data.name === '' ||
                data.product_code === '' || 
                data.area === '' ||
                data.currency === '' ||
                Number(data.client_admin_fee) === 0 ||
                String(data.wholesale_price).length === 0
            ) {
                alert('please fill out this form')
                return false
            } else {
                if( window.confirm('Do you confirm to save the input')) {
                    const response: MainResponse<string> | null = await fetchAPI(`products/inventory`, 'POST', data);
                    if (response?.code === 200) {
                        alert(response?.message ?? successSaveMessage);
                        handleFormClear();
                    } else {
                    alert(response?.message ?? failedSaveMessage);
                    }
                }   
            }         
        } catch (error: any) {
            alert(error?.message ?? failedGetDataMessage);
        }
    }

    const handleChangeCategory = async (event:ChangeEvent<HTMLInputElement>) => {
        fetchCategory({categoryLike: event.target.value});
    }

    const handleFormClear = () => {
        setData(initialData);
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root, .MuiFormControl-root': { mx: 3, my: 2, width: '30%' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <FormControl sx={{width: '30%'}} size={"small"}>
                    <InputLabel id="partner_code-select-label">Partner</InputLabel>
                    <Select
                        labelId="partner_code-select-label"
                        id="Partnpartner_codeer-select"
                        name="partner_code"
                        value={data.partner_code}
                        label="Partner"
                        onChange={(event:any) => {onInputChange(event); handleChangeCategory(event)}}
                        defaultValue={``}
                        required={true}
                        error={DataValidation.partner_code}
                    >
                        {DataPartner.map((item, i) => (
                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>    
                        ))}
                    </Select>
                    <FormHelperText>{DataValidation.partner_code ? LABEL_REQUIRED: ''}</FormHelperText>
                </FormControl>
                <FormControl fullWidth size={"small"}>
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select
                        labelId="category-select-label"
                        id="category-select"
                        name="category_code"
                        value={data.category_code}
                        label="Category"
                        onChange={(event:any) => onInputChange(event)}
                        defaultValue={``}
                        required={true}
                        error={DataValidation.category_code}
                    >
                        {DataCategory.map((item, i) => (
                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>    
                        ))}
                    </Select>
                    <FormHelperText>{DataValidation.category_code ? LABEL_REQUIRED: ''}</FormHelperText>
                </FormControl>
            </div>
            <div>
                <TextField
                    label="Name"
                    size={"small"}
                    name='name'
                    value={data.name}
                    onChange={onInputChange}
                    required
                    error={DataValidation.name}
                    helperText={DataValidation.name ? LABEL_REQUIRED: ''}
                />
                <TextField
                    label="SKU"
                    size={"small"}
                    name='sku'
                    value={data.sku}
                    onChange={onInputChange}
                    required
                    error={DataValidation.sku}
                    helperText={DataValidation.sku ? LABEL_REQUIRED: ''}
                />
            </div>
            <div>
                <TextField
                    label="Product Code"
                    size={"small"}
                    name='product_code'
                    required
                    value={data.product_code}
                    onChange={onInputChange}
                    error={DataValidation.product_code}
                    helperText={DataValidation.product_code ? LABEL_REQUIRED: ''}
                />
                <TextField
                    label="Payee Code"
                    size={"small"}
                    name='payee_code'
                    value={data.payee_code}
                    onChange={onInputChange}
                />
            </div>
            <div>
                <TextField
                    label="Area"
                    size={"small"}
                    name='area'
                    value={data.area}
                    onChange={onInputChange}
                    required
                    error={DataValidation.area}
                    helperText={DataValidation.area ? LABEL_REQUIRED: ''}
                />
                <FormControl fullWidth size={"small"}>
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        name="type"
                        value={data.type}
                        label="Type"
                        required
                        onChange={(event:any) => onInputChange(event)}
                        defaultValue={0}
                    >   
                        <MenuItem value={0} >-- Choose --</MenuItem>
                        <MenuItem value={7} >PLN Prepaid</MenuItem>
                        <MenuItem value={8} >PLN Postpaid</MenuItem>
                        <MenuItem value={11} >PDAM</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
                <TextField
                    label="Currency"
                    size={"small"}
                    name='currency'
                    value={data.currency}
                    defaultValue={`IDR`}
                    disabled
                    onChange={onInputChange}
                />
                <TextField
                    label="Wholesale Price"
                    size={"small"}
                    type='number'
                    name='wholesale_price'
                    value={data.wholesale_price}
                    onChange={onInputChange}
                    error={DataValidation.wholesale_price}
                    required
                    helperText={DataValidation.wholesale_price ? LABEL_REQUIRED: ''}
                />
            </div>
            <div>
                <TextField
                    label="Client Admin Fee"
                    size={"small"}
                    type='number'
                    name='client_admin_fee'
                    value={data.client_admin_fee}
                    onChange={onInputChange}
                    error={DataValidation.client_admin_fee}
                    required
                    helperText={DataValidation.client_admin_fee ? LABEL_REQUIRED: ''}
                />
            </div>
            <div style={{margin: 24, display: 'flex', justifyContent: 'left'}} >
                <Button color="secondary" sx={{mr:2}} onClick={handleFormClear}>
                    Clear
                </Button>
                <Button color="success" variant="contained" onClick={handleFormSubmit}>
                    Submit
                </Button>
            </div>
        </Box>
    );
}