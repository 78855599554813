import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainResponse, MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import useInputChange from "customhook/useInputChange";
import {
  failedGetDataMessage,
  failedSaveMessage,
  successSaveMessage,
} from "helpers/defaultMessage";
import { UserData } from ".";
import MultiSelectSearch from "components/MultiSelectSearch";

const UserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userIdParam = location.state?.userIdParam;
  const currentPath = location.pathname;

  const pathParent = "/cms/users";
  const isView = currentPath === `${pathParent}/view`;

  const noAction = () => {};

  const initialData: UserData = useMemo(() => {
    return {
      id: "",
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      password: undefined,
      active: false,
      roles: [],
      groups: [],
      categories: [],
      partners: [],

      password_update: false,
      status_label: "",
      role_names: "",

      roles_selected: [],
      groups_selected: [],
      categories_selected: [],
      partners_selected: [],

      roles_selected_initiated: false,
      groups_selected_initiated: false,
      categories_selected_initiated: false,
      partners_selected_initiated: false,
    };
  }, []);

  const { data, setData, onInputChange } =
    useInputChange<UserData>(initialData);

  const back = () => {
    setData(initialData);
    navigate(pathParent);
  };

  const fetchData = useCallback(async () => {
    try {
      const response: MainRowsResponse<UserData> | null = await fetchAPI(
        `users/${userIdParam}`
      );

      const data =
        (response?.data?.rows?.length ?? 0) > 0
          ? response?.data?.rows?.[0] ?? initialData
          : initialData;

      setData(data);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, [userIdParam, initialData, setData]);

  const saveItem = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(data.email)) {
      alert("Invalid email format");
      return;
    }

    try {
      const urlPath = userIdParam ? `users/${data?.id}` : "users";

      const method = userIdParam ? "PUT" : "POST";

      const saveData = {
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        username: data.username,
        email: data.email,
        password: data.password,
        active: data.active,
        roles: data.roles_selected?.map((role: any) => role?.value) ?? [],
        groups: data.groups_selected?.map((role: any) => role?.value) ?? [],
        categories:
          data.categories_selected?.map((role: any) => role?.value) ?? [],
        partners: data.partners_selected?.map((role: any) => role?.value) ?? [],
      };

      const response: MainResponse<string> | null = await fetchAPI(
        urlPath,
        method,
        saveData
      );

      if (response?.code === 200) {
        alert(response?.message ?? successSaveMessage);
        back();
      } else {
        alert(response?.message ?? failedSaveMessage);
      }
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedSaveMessage);
    }
  };

  const setInititalRolesSelected = useCallback(() => {
    if (data?.roles_selected_initiated === false && data.roles?.length > 0) {
      const rolesSelected = data.roles.map((role) => {
        return { label: role.name, value: role.id };
      });

      setData({
        roles_selected: rolesSelected,
        roles_selected_initiated: true,
      });
    }
  }, [data?.roles_selected_initiated, data.roles, setData]);

  const setInititalGroupsSelected = useCallback(() => {
    if (data?.groups_selected_initiated === false && data.groups?.length > 0) {
      const groupsSelected = data.groups.map((group) => {
        return { label: group.name, value: group.code };
      });

      setData({
        groups_selected: groupsSelected,
        groups_selected_initiated: true,
      });
    }
  }, [data?.groups_selected_initiated, data.groups, setData]);

  const setInititalCategoriesSelected = useCallback(() => {
    if (
      data?.categories_selected_initiated === false &&
      data.categories?.length > 0
    ) {
      const categoriesSelected = data.categories.map((category) => {
        return { label: category.name, value: category.code };
      });

      setData({
        categories_selected: categoriesSelected,
        categories_selected_initiated: true,
      });
    }
  }, [data?.categories_selected_initiated, data.categories, setData]);

  const setInititalPartnersSelected = useCallback(() => {
    if (
      data?.partners_selected_initiated === false &&
      data.partners?.length > 0
    ) {
      const partnersSelected = data.partners.map((partner) => {
        return { label: partner.name, value: partner.code };
      });

      setData({
        partners_selected: partnersSelected,
        partners_selected_initiated: true,
      });
    }
  }, [data?.partners_selected_initiated, data.partners, setData]);

  const onSelectRolesChange = (rolesSelected: any): void => {
    setData({ roles_selected: rolesSelected });
  };

  const onSelectGroupsChange = (groupsSelected: any): void => {
    setData({ groups_selected: groupsSelected });
  };

  const onSelectCategoriesChange = (groupsSelected: any): void => {
    setData({ categories_selected: groupsSelected });
  };

  const onSelectPartnersChange = (groupsSelected: any): void => {
    setData({ partners_selected: groupsSelected });
  };

  const onSaveClicked = async () => {
    saveItem();
  };

  const onCancelClicked = () => {
    back();
  };

  useEffect(() => {
    if (userIdParam) {
      fetchData();
    }
  }, [fetchData, userIdParam]);

  useEffect(() => {
    setInititalRolesSelected();
    setInititalGroupsSelected();
    setInititalCategoriesSelected();
    setInititalPartnersSelected();
  }, [
    setInititalRolesSelected,
    setInititalGroupsSelected,
    setInititalCategoriesSelected,
    setInititalPartnersSelected,
  ]);

  return (
    <div className="mx-auto max-w-2xl mt-5">
      <div className="flex justify-between">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Users
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use to define of user data
          </p>
        </div>
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First Name
            </label>
            <div className="mt-2">
              <input
                id="input-firstname"
                name="firstname"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data?.firstname ?? ""}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-group-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last Name
            </label>
            <div className="mt-2">
              <input
                id="input-lastname"
                name="lastname"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data?.lastname ?? ""}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                id="input-username"
                name="username"
                type="input"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data?.username ?? ""}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="input-partner-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="input-email"
                name="email"
                type="email"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={isView ? noAction : onInputChange}
                value={data?.email ?? ""}
              />
            </div>
          </div>

          {!userIdParam && (
            <div className="sm:col-span-4">
              <label
                htmlFor="input-partner-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="input-password"
                  name="password"
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={isView ? noAction : onInputChange}
                  value={data.password}
                />
              </div>
            </div>
          )}

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Roles
            </label>
            <div className="mt-2">
              <MultiSelectSearch
                endpoint="roles"
                queryKey="name"
                labelKey="name"
                valueKey="id"
                selecteds={data.roles_selected}
                onChange={isView ? noAction : onSelectRolesChange}
                isViewOnly={isView}
                isUsingSearchKey={true}
              />
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Group Business
            </label>
            <div className="mt-2">
              <MultiSelectSearch
                endpoint="business/groups"
                queryKey="group_name"
                labelKey="group_name"
                valueKey="group_code"
                selecteds={data.groups_selected}
                onChange={isView ? noAction : onSelectGroupsChange}
                isViewOnly={isView}
              />
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Business Category
            </label>
            <div className="mt-2">
              <MultiSelectSearch
                endpoint="business/categories"
                queryKey="category_name"
                labelKey="category_name"
                valueKey="category_code"
                selecteds={data.categories_selected}
                onChange={isView ? noAction : onSelectCategoriesChange}
                isViewOnly={isView}
              />
            </div>
          </div>

          <div className="col-span-full sm:col-span-4">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Partners
            </label>
            <div className="mt-2">
              <MultiSelectSearch
                endpoint="partners"
                queryKey="partner_name"
                labelKey="partner_name"
                valueKey="partner_code"
                selecteds={data.partners_selected}
                onChange={isView ? noAction : onSelectPartnersChange}
                isViewOnly={isView}
              />
            </div>
          </div>

          <div className="col-span-full flex items-center">
            <input
              type="checkbox"
              id="checkbox-active"
              className="form-checkbox h-5 w-5 text-blue-600"
              name="active"
              checked={data.active}
              onChange={isView ? noAction : onInputChange}
            />
            <label htmlFor="active" className="ml-2 text-gray-700">
              Active
            </label>
          </div>

          {userIdParam && (
            <>
              <div className="col-span-full flex items-center">
                <input
                  type="checkbox"
                  id="checkbox-password-update"
                  className="form-checkbox h-5 w-5 text-blue-600"
                  name="password_update"
                  checked={data.password_update}
                  onChange={isView ? noAction : onInputChange}
                />
                <label htmlFor="active" className="ml-2 text-gray-700">
                  Update Password
                </label>
              </div>

              {data.password_update && (
                <div className="sm:col-span-4">
                  <label
                    htmlFor="input-partner-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="input-password"
                      name="password"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={isView ? noAction : onInputChange}
                      value={data.password}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {!isView && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onCancelClicked}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSaveClicked}
            className="bg-pink-600 text-white rounded-md px-20 py-2"
          >
            <span className="font-semibold">
              {userIdParam ? "Update" : "Save"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default UserForm;
