import { fetchAPI } from "helpers/apiHelpers";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";

interface MultiSelectSearchProps {
  endpoint: string;
  queryKey: string;
  labelKey: string;
  valueKey: string;
  selecteds: any[];
  onChange: (selectedOptions: any) => void;
  isViewOnly: boolean;
  isUsingSearchKey?: boolean;
}

const MultiSelectSearch: React.FC<MultiSelectSearchProps> = ({
  endpoint,
  queryKey,
  labelKey,
  valueKey,
  selecteds,
  onChange,
  isViewOnly,
  isUsingSearchKey,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(selecteds);

  const loadOptions = async (inputValue: string) => {
    try {
      const queryData = isUsingSearchKey
        ? {
            search: inputValue,
            fields: queryKey,
          }
        : {
            [queryKey]: inputValue,
          };

      const url = endpoint + "?" + queryString.stringify(queryData);

      const response: any = await fetchAPI(url);
      const dataResponse = Array.isArray(response.data)
        ? response.data
        : response.data?.rows ?? [];
      const formattedOptions = dataResponse?.map((item: any) => ({
        value: item[valueKey],
        label: item[labelKey],
      }));
      return formattedOptions;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const colorStyles: StylesConfig<true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#F8EDFF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#3D3B40",
      fontWeight: "700",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#3D3B40",
      ":hover": {
        backgroundColor: "#F8EDFF",
        color: "#3D3B40",
      },
    }),
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(Array.isArray(selected) ? selected : [selected]);

    if (onChange) {
      onChange(selected);
    }
  };

  useEffect(() => {
    setSelectedOptions(selecteds);
  }, [selecteds]);

  const noAction = () => {};

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      value={selectedOptions}
      onChange={isViewOnly ? noAction : handleChange}
      defaultOptions={false}
      isSearchable={true}
      isMulti={true}
      styles={colorStyles}
      placeholder="Search and select..."
    />
  );
};

export default MultiSelectSearch;
