import { useLocation } from "react-router-dom";

export type Menu = {
  id: number;
  name: string;
  path: string;
  icon: string | null;
  priority: number;
  is_view: boolean;
  is_create: boolean;
  is_edit: boolean;
  is_delete: boolean;
  is_publish: boolean;
  is_download: boolean;
  children: Menu[];
};

const useCurrentMenu = (): Menu => {
  const location = useLocation();
  const currentPath = location.pathname;

  const menusSession = sessionStorage.getItem("menus") ?? "[]";
  const menus = JSON.parse(menusSession) ?? [];

  const currentMenu: Menu =
    menus
      .flatMap((parentMenu: any) => parentMenu.children)
      .find((menu: any) => menu.path === currentPath) || {};

  return currentMenu;
};

export default useCurrentMenu;
