import React from "react";

const TransactionStatusComponent = ({status}:{status:string}) => {
    const capitalize = (word: string) => {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    };

    const classNameStyle: any = {
      Initial: "bg-gray-100 text-gray-800",
      Progress: "bg-sky-100 text-sky-800",
      Success: "bg-green-200 text-green-800",
      Pending: "bg-amber-100 text-amber-800",
      Failed: "bg-rose-200 text-rose-800",
      Refund: "bg-emerald-200 text-emerald-800",
      Done: "bg-teal-200 text-teal-800",
    };

    return (
      <>
        {Object.keys(classNameStyle).map((item, i) => (
          <>
            {status === item && (
              <span
                className={`text-lg font-medium me-2 px-5 py-2 rounded ${classNameStyle[item]}`}
              >
                {capitalize(item === 'Progress' ? `In ${item}` : item)}
              </span>
            )}
          </>
        ))}
      </>
    );
  };

  export default TransactionStatusComponent;