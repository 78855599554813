import ListView from "components/ListView/ListView";
import useCurrentMenu from "customhook/useCurrentMenu";
import { MainRowsResponse, fetchAPI } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import moment from "moment";
import { ProductInventoryData } from "pages/ProductInventory";
import { ProductPriceData } from "./PricePopup";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";

export type ProductPublishData = {
  publish_id: string;
  product_id: string;
  category_code?: string;
  category_name?: string;
  partner_code?: string;
  partner_name?: string;
  sku?: string;
  product_name?: string;
  product_code?: string;
  wholesale_price?: number;
  period_from?: string;
  period_from_initial?: string;
  period_to?: string;
  period_from_formatted?: string;
  period_to_formatted?: string;
  status_config?: boolean;
  status_config_label?: string;
  status_publish?: string;
  is_initial_status_on_publish?: boolean;
  is_initial_status_on_inactive?: boolean;
  status_publish_label?: string;
  prices?: ProductPriceData[];
  product_inventories: ProductInventoryData[];
  product_inventory_selected?: ProductInventoryData;
  is_product_select_popup_show: boolean;
  is_product_detail_popup_show: boolean;
  is_price_popup_add_show: boolean;
  created_at?: string;
  updated_at?: string;
};

type CategoryOtionType = {
  label: string;
  value: string;
};

export const statusPublishList = [
  { label: "Draft", value: "0" },
  { label: "Publish", value: "1" },
  { label: "Inactive", value: "-1" },
];

const ProductPublish = () => {
  const currentMenu = useCurrentMenu();
  const navigate = useNavigate();
  const PrivilegedUsers = usePrivilegedUsers();

  const pathParent = "/cms/products-publish";
  const pathAdd = `${pathParent}/add`;
  const pathView = `${pathParent}/view`;
  const pathEdit = `${pathParent}/edit`;

  const [datas, setDatas] = useState<ProductPublishData[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const itemsPerPageDefault = 10;

  const [categoriesOptions, setCategoriesOptions] = useState<
    CategoryOtionType[]
  >([]);

  const fetchDatas = useCallback(async (query?: any) => {
    const queryData = {
      ...query,
      sortBy: query?.sortBy ?? "created_at.desc",
      category_code: query?.category,
      category: undefined,
      size: query?.itemsPerPage ?? itemsPerPageDefault,
      itemsPerPage: undefined,
      page: query?.page,
    };

    if(!queryData?.search) {
      delete queryData?.filterBy;
    }

    try {
      const response: MainRowsResponse<ProductPublishData> | null =
        await fetchAPI(`products/publish?${queryString.stringify(queryData)}`);

      const datas =
        response?.data?.rows?.map((data) => {
          return {
            ...data,
            status_config_label: data.status_config === true ? "Publish" : "Draft",
            status_publish_label: data.status_publish?.toString() === "1" ? "Publish" : data.status_publish?.toString() === "0" ? "Draft" : "Inactive",
            period_from_formatted: moment(data.period_from).format("DD MMM YYYY"),
            period_to_formatted: moment(data.period_to).format("DD MMM YYYY"),
            created_at: data.created_at ? moment(data.created_at).format("DD MMM YYYY HH:mm:ss") : '',
            updated_at: data.updated_at ? moment(data.updated_at).format("DD MMM YYYY HH:mm:ss") : '',
          };
        }) ?? [];

      setDatas(datas);
      setRowsTotal(response?.data?.count ?? 0);
    } catch (error: any) {
      console.error(error);
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const fetchCategory = useCallback(async () => {
    try {
      const response: MainRowsResponse<{ rows?: []; count?: number }> | null =
        await fetchAPI(`business/categories?inCode=${PrivilegedUsers.categories.toString()}`);
      const datas =
        response?.data?.rows.map((data: any) => {
          return {
            label: data.category_name,
            value: data.category_code,
          };
        }) ?? [];
      setCategoriesOptions(datas);
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const onListEventAction = async (query: any) => {
    fetchDatas(query);
  };

  useEffect(() => {
    fetchDatas();
    fetchCategory();
  }, [fetchCategory, fetchDatas]);

  const columns: any = [
    { label: "Publish ID", field: "publish_id" },
    { label: "SKU", field: "sku" },
    { label: "Product Name", field: "product_name" },
    { label: "Category Name", field: "category_name" },
    { label: "Partner ID", field: "partner_code" },
    { label: "Partner Name", field: "partner_name" },
    { label: "Wholesale Price", field: "wholesale_price" },
    { label: "Period From", field: "period_from_formatted" },
    { label: "Period To", field: "period_to_formatted" },
    { label: "Status Publish", field: "status_publish_label" },
    { label: "Created Time", field: "created_at" },
    { label: "Update Time ", field: "updated_at" },
  ];

  const filter = [
    {
      label: "Search By",
      field: "filterBy",
      type: "select",
      value: "",
      options: [
        { label: "Product Name", value: "product_name" },
        { label: "Partner ID", value: "partner_code" },
        { label: "Partner Name", value: "partner_name" },
      ],
    },
    { label: "Search Data", field: "search", type: "text", value: "" },
    {
      label: "Category",
      field: "category",
      type: "select",
      value: "",
      options: categoriesOptions,
    },
    { label: "Period", field: "periode", type: "date", value: "" },
    {
      label: "Sort By",
      field: "sortBy",
      type: "select",
      value: "",
      options: [
        { label: "Created Time - Asc", value: "created_at.asc" },
        { label: "Created Time - Desc", value: "created_at.desc" },
      ],
    },
  ];

  const columnsAction: any = [
    {
      type: "view",
      onClick: (publishItem: any) => {
        navigate(pathView, {
          state: {
            publishIdParam: publishItem["publish_id"],
            productIdParam: publishItem["product_id"],
          },
        });
      },
    },
    {
      type: "edit",
      onClick: (publishItem: any) => {
        navigate(pathEdit, {
          state: {
            publishIdParam: publishItem["publish_id"],
            productIdParam: publishItem["product_id"],
          },
        });
      },
    },
  ];

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-white my-5">
      <ListView
        columns={columns}
        rowItems={datas}
        filter={filter}
        eventAction={(query: any) => onListEventAction(query)}
        columnsAction={columnsAction}
        menu={currentMenu}
        btnAddItem={currentMenu.is_create ? pathAdd : undefined}
        pagination={{
          itemsPerPage: itemsPerPageDefault,
          countRows: rowsTotal,
        }}
      />
    </div>
  );
};

export default ProductPublish;
