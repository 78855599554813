export function formatRupiah(amount: number): string {
  const formattedRupiah = amount.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formattedRupiah;
}
